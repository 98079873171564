/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Routes, mapToCanActivate } from '@angular/router';
import { StaffGuard } from './public/services/auth/auth-guard.service';
import { HomepageComponent } from './public/pages/home/homepage.component';
import { PublicRoutes } from './public.routes';
import { AdminRoutes } from './admin.routes';

export const routes: Routes = [
    {
      path: '',
      component: HomepageComponent,
      title: 'Vereenigde Compagnie - Roleplay Community'
    },
    ...PublicRoutes,
    { 
      path: 'admin',
      canActivate: mapToCanActivate([StaffGuard]),
      title: 'VCRP - Admin',
      children: AdminRoutes
    }
];
