/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Environment } from "../interfaces/environment";

export const environment: Environment = {
    production: true,
    gameUrl: `game.vcrp.nl:30120`,
    apiUrl: `https://api.vcrp.nl`,
    discord: {
      token: 'OTI3MzY4Njc5OTE2NzExOTY3.Gzrmw0.4sLG-EZY--GtjSntYAY_pFkHsYqavq-ptYBjeI',
      client: {
        id: '927368679916711967',
        secret: 'dP0aF2iCopHynDb53hPGT33y1T6ARkSj'
      },
      oauth: 'https://discord.com/oauth2/authorize?client_id=927368679916711967&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Flogin&scope=identify+guilds+email',
      server: {
        id: '927368614347153408'
      } 
    }
};