<div class="panel">
  <h3 class="p-4 pb-0">Privacy Policy van Vereenigde Compagnie</h3>
  <div class="p-4">
    <h1>1. Inleiding</h1>
    <p>Vereenigde Compagnie (hierna "wij" of "ons") respecteert uw privacy en is toegewijd aan het beschermen van uw persoonsgegevens. Deze privacy policy legt uit welke gegevens wij verzamelen, hoe wij deze gebruiken, opslaan en beschermen, en welke rechten u heeft met betrekking tot uw gegevens.</p>
    <hr class="mt-5 mb-3" />
    <h1>2. Contactinformatie</h1>
    <p>Voor vragen of verzoeken met betrekking tot deze privacy policy, kunt u contact opnemen met:<br>
    E-mailadres: support&#64;vcrp.nl</p>
    <hr class="mt-5 mb-3" />
    <h1>3. Welke gegevens verzamelen wij?</h1>
    <p>Wij verzamelen en bewaren de volgende persoonsgegevens:</p>
    <ul>
        <li><strong>E-mailadres:</strong> Dit wordt opgehaald wanneer u inlogt via Discord.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>4. Hoe verzamelen wij uw gegevens?</h1>
    <p>Wij verzamelen uw e-mailadres wanneer u inlogt via Discord. Dit gebeurt op een manier die ervoor zorgt dat we enkel de noodzakelijke informatie verkrijgen zonder onnodige gegevens te verzamelen.</p>
    <hr class="mt-5 mb-3" />
    <h1>5. Doeleinden van de gegevensverwerking</h1>
    <p>Wij gebruiken uw e-mailadres uitsluitend voor de volgende doeleinden:</p>
    <ul>
        <li><strong>Accountherstel:</strong> Uw e-mailadres wordt gebruikt om uw account te herstellen in het geval dat u geen toegang meer heeft tot Discord. Dit zorgt ervoor dat u te allen tijde toegang kunt blijven houden tot uw account en onze diensten.</li>
        <li><strong>Klantenservice:</strong> Uw e-mailadres helpt ons om u snel en effectief te ondersteunen bij eventuele problemen met uw account.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>6. Delen van gegevens met derden</h1>
    <p>Wij delen uw persoonsgegevens niet met derden. Uw gegevens worden uitsluitend intern gebruikt en niet voor commerciële of marketingdoeleinden gedeeld met externe partijen.</p>
    <hr class="mt-5 mb-3" />
    <h1>7. Rechten van gebruikers</h1>
    <p>U heeft diverse rechten met betrekking tot uw persoonsgegevens:</p>
    <ul>
        <li><strong>Recht op correctie:</strong> U kunt zelf onjuiste of onvolledige gegevens te corrigeren.</li>
        <li><strong>Recht op verwijdering:</strong> U kunt verzoeken om uw gegevens te laten verwijderen. Dit houdt in dat uw account en alle bijbehorende gegevens permanent verwijderd worden. U kunt dit ook zelf doen via de accountinstellingen.</li>
        <li><strong>Recht om bezwaar te maken:</strong> U kunt bezwaar maken tegen de verwerking van uw gegevens indien u van mening bent dat de verwerking onterecht is.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>8. Beveiliging van gegevens</h1>
    <p>Wij nemen de bescherming van uw gegevens zeer serieus. Hoewel specifieke technische maatregelen niet van toepassing zijn, hanteren wij organisatorische maatregelen om uw gegevens te beschermen, zoals:</p>
    <ul>
        <li>Toegang tot uw gegevens is beperkt tot geautoriseerde personen binnen onze organisatie die deze informatie nodig hebben om hun taken uit te voeren.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>9. Bewaartermijn</h1>
    <p>Wij bewaren uw persoonsgegevens voor onbepaalde duur, zolang u een account bij ons heeft. Zodra u verzoekt om uw account te verwijderen of dit zelf doet, worden uw gegevens binnen een redelijke termijn permanent verwijderd.</p>
    <hr class="mt-5 mb-3" />
    <h1>10. Wettelijke basis voor gegevensverwerking</h1>
    <p>Wij verwerken uw gegevens op basis van uw expliciete toestemming. Door in te loggen via Discord en uw e-mailadres te verstrekken, geeft u ons toestemming om uw gegevens te verwerken voor de hierboven beschreven doeleinden. U kunt uw toestemming op elk moment intrekken door contact met ons op te nemen via support&#64;vcrp.nl, of via de instellingen van uw account.</p>
    <hr class="mt-5 mb-3" />
    <h1>11. Wijzigingen aan deze privacy policy</h1>
    <p>Wij behouden ons het recht voor om deze privacy policy te wijzigen. Eventuele wijzigingen zullen op onze website worden gepubliceerd en waar nodig aan u worden gecommuniceerd via e-mail.</p>
    <hr class="mt-5 mb-3" />
    <h1>12. Contact voor privacyvragen</h1>
    <p>Als u vragen of klachten heeft over deze privacy policy of uw persoonsgegevens, kunt u contact opnemen met onze support via support&#64;vcrp.nl.</p>
  </div>
</div>