/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
**************************************************/
import { Component, OnInit } from '@angular/core';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { SocketUpdateMessage } from '../../../../../../../library/interfaces/socket';
import { Vehicle, VehicleType } from '../../../../../../../library/interfaces/vehicle';
import { ActiveVehiclesComponent } from '../../../components/widgets/active-vehicles/active-vehicles.component';
import { CommonModule } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { ApiService } from '../../../../public/services/api.service';
import { Router, RouterLink } from '@angular/router';
import { DataService } from '../../../../public/services/data.service';
import { LiveBlip, MapComponent } from '../../../../public/components/map/map.component';
import { Coordinates } from '../../../../../../../library/interfaces/location';

@Component({
  selector: 'app-vehicles',
  standalone: true,
  imports: [ActiveVehiclesComponent, CommonModule, RouterLink, MapComponent],
  templateUrl: './vehicles.component.html',
  styleUrl: './vehicles.component.scss'
})
export class VehiclesComponent implements OnInit {
  public pendingDeletes: string[] = [];
  public fetchingData = true;
  public blipList: LiveBlip[] = [];
  public vehicleList: Vehicle[] = [
  //   { "entities": {
  //       "serverEntity": 131370
  //   },
  //   "ids": {
  //       "vehicleId": "1d203dff-a101-4280-8d6b-deef1f47ba56",
  //       "networkId": 7,
  //       "serverId": 131370
  //   },
  //   "owner": {
  //       "userId": 1,
  //       "characterSlot": 0,
  //       "privatelyOwned": false,
  //       "adminVehicle": true
  //   },
  //   "license": "VCRP-ADM",
  //   "lock": 0,
  //   "vehicleData": {
  //       "name": "Admin FIB",
  //       "model": "fbi",
  //       "manufacturer": "VCRP",
  //       "buildYear": 2003,
  //       "category": "emergency",
  //       "type": "car"
  //   }
  // }
  ];
  private cachedVehicleList: Vehicle[] = [];
  private cacheTime = 0;

  constructor(
    private websocketService: WebsocketService,
    private clipboard: Clipboard,
    private apiService: ApiService,
    private dataService: DataService,
    private router: Router
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
        switch(update.type){
          case 'vehicles':
            if(JSON.stringify(this.cachedVehicleList) !== JSON.stringify(update.data) && Math.abs(Date.now() - this.cacheTime) > 1000){
              this.setVehicleList(update.data);
              this.cachedVehicleList = update.data;
              this.cacheTime = Date.now();
            }
            break;
        }
    });
  }

  public async removeVehicle(uuid: string){
    if(!this.pendingDeletes.includes(uuid)){
      this.pendingDeletes.push(uuid);
      const removeVehicleCall = await this.apiService.call('/vehicles/remove/by-uuid/' + uuid);
      if(removeVehicleCall.success && removeVehicleCall.data){
        this.pendingDeletes = this.pendingDeletes.filter((uuidFromList) => uuidFromList !== uuid);
        this.vehicleList = this.vehicleList.filter((vehicle) => vehicle.world.uuid !== uuid);
      } else {
        this.pendingDeletes = this.pendingDeletes.filter((uuidFromList) => uuidFromList !== uuid);
      }
    }
  }

  private setVehicleList(vehicleList: Vehicle[]){
    this.vehicleList = vehicleList;
    
    const newBlipList: LiveBlip[] = [];
    for(let vehicle of this.vehicleList){
      newBlipList.push({
        name: `[${vehicle.world.license}] ${vehicle.vendor.manufacturer} ${vehicle.vendor.name}`,
        coordinates: vehicle.world?.location as Coordinates,
        blipSprite: this.mapVehicleTypeToBlip(vehicle.vendor.type),
        type: 'vehicle',
        id: vehicle.world.uuid
      });
    }

    this.blipList = newBlipList;
  }

  public handleClickedMarker(uuid: any){
    if(this.vehicleList.find((vehicle) => vehicle.world.uuid === uuid)){
      this.router.navigate(['/admin/vehicles/active/' + uuid]);
    }
  }

  private mapVehicleTypeToBlip(vehicleType: VehicleType){
    switch(vehicleType){
      case 'car':
        return 225;
      case 'motor':
        return 226;
      case 'other':
        return 1;
      case 'helicopter':
        return 43;
      case 'trailer':
        return 479;
    }
  }

  async ngOnInit(): Promise<void> {
    this.setVehicleList(await this.dataService.getActiveVehicles());
    this.fetchingData = false;
  }

  public copyValue(value: any){
    this.clipboard.copy(value);
  }
}
