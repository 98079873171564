<div class="loader-wrapper" *ngIf="loading">
    <div class="loader"></div>
</div>
<div class="d-flex" *ngIf="!loading">
    <div class="d-flex w-100 h-100" *ngIf="!playerFound">
        <div class="card w-100 p-4">
            <h1>Speler niet gevonden</h1>
            <h5>UUID: {{ userId }} is niet gevonden in de actieve spelers lijst.</h5>
        </div>
    </div>
    <div class="d-flex w-100 flex-column h-100" *ngIf="playerFound && userHasCharacters">
        <div class="d-flex w-100 mb-2">
            <div class="card me-2">
                <h1 class="title" *ngIf="player.discordName && player.discordAvatar">
                    <div class="avatar">
                        <img src="{{ player.discordAvatar }}"/>
                    </div>
                    <div class="name">
                        {{ player.discordName }}
                    </div>
                </h1>
                <h1 class="p-3" *ngIf="!player.discordAvatar || !player.discordName">Gebruiker ID: {{ player.ids.userId }}</h1>
                <div class="player">
                    <table class="table table-borderless table-striped w-100">
                        <tbody class="w-100">
                            <tr>
                                <td class="ps-2 pe-0"><i class="fa-fw fa-brands fa-discord"></i>Rollen</td>
                                <td>{{player.roles}}</td>
                            </tr>
                            <tr>
                                <td class="ps-2 pe-0"><i class="fa-fw fa-solid fa-users"></i>Karakters</td>
                                <td>{{player.characters.length}}</td>
                            </tr>
                            <tr>
                                <td class="ps-2 pe-0"><i class="fa-fw fa-solid fa-list"></i>IDs</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="ps-4 pe-0"><i class="fa-fw fa-solid fa-id-badge"></i>VCRP User ID</td>
                                <td><small>{{ player.ids.userId }}</small></td>
                            </tr>
                            <tr>
                                <td class="ps-4 pe-0"><i class="fa-fw fa-brands fa-discord"></i>Discord</td>
                                <td><small>{{ player.ids.discord }}</small></td>
                            </tr>
                            <tr>
                                <td class="ps-2 pe-0"><i class="fa-fw fa-solid fa-calendar-circle-user"></i>Joined</td>
                                <td>{{player.dateInsert | date : 'yyyy-MM-dd'}}</td>
                            </tr>
                            <tr>
                                <td class="ps-2 pe-0"><i class="fa-fw fa-solid fa-calendar-clock"></i>Laatste update</td>
                                <td>{{player.dateLastUpdate | date : 'yyyy-MM-dd'}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <div class="p-2">
                        <h5 class="pt-2">Acties</h5>
                        <div class="d-flex">
                            <div class="btn w-50 btn-secondary justify-content-center mb-3 p-3 ms-2 me-2"><i class="fa-solid fa-pencil pe-2"></i></div>
                            <div class="btn w-50 btn-danger btn-danger justify-content-center mb-3 p-3 ms-2 me-2">
                                <span *ngIf="!pendingKick"><i class="fa-solid fa-person-to-door pe-2"></i></span>
                                <span *ngIf="pendingKick"><div class="loader"></div></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-column flex-grow-1 me-2">
                <div class="d-flex w-100 account-row" *ngFor="let character of player.characters; let i = index">
                    <div [class.mt-2]="i > 0" [class.me-2]="activeCharacter === character.slot" [class.w-75]="character.location && activeCharacter === character.slot" [class.w-100]="!character.location || activeCharacter !== character.slot" class="card">
                        <h1 class="p-3 d-flex text-success align-items-center" [class.text-success]="activeCharacter === character.slot">
                            <div class="flag flag-{{character.nationality}} me-1"></div>
                            <div class="name flex-grow-1">{{ character.firstName }} {{ character.lastName }}</div>
                            <div [class.live]="activeCharacter === character.slot" class="live-icon ps-2"></div>
                        </h1>
                        <div class="d-flex">
                            <div class="d-flex avatar-container p-2">
                                <div class="avatar"></div>
                            </div>
                            <div class="d-flex flex-grow-1">
                                <div class="d-flex w-30">
                                    <table class="table table-sm table-borderless w-100">
                                        <tbody class="w-100">
                                            <tr *ngIf="activeCharacter === character.slot">
                                                <td class="pe-0 fw-bold">Activiteit</td>
                                                <td *ngIf="character.activity">{{ character.activity | activityNameMap }}</td>
                                                <td *ngIf="!character.activity">{{ "roaming" | activityNameMap }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pe-0 fw-bold">Geboortedatum</td>
                                                <td>{{ character.birthDate | date : 'yyyy-MM-dd' }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pe-0 fw-bold">Speeltijd</td>
                                                <td>{{ character.playTimeInMinutes | durationTransform }}</td>
                                            </tr>
                                            <tr>
                                                <td class="pe-0 fw-bold">Aangemaakt</td>
                                                <td>{{ character.dateInsert | date : 'yyyy-MM-dd' }}</td>
                                            </tr>
                                            <tr *ngIf="activeCharacter !== character.slot">
                                                <td class="pe-0 fw-bold">Laatste bekende locatie</td>
                                                <td *ngIf="character.location">{{ character.location.name.streetName }}<span *ngIf="character.location.name.crossingRoad">, {{ character.location.name.crossingRoad }}</span></td>
                                                <td *ngIf="!character.location">Onbekend</td>
                                            </tr>
                                            <tr>
                                                <td class="pe-0 fw-bold">Laatste login</td>
                                                <td>{{ character.dateLastLogin | date : 'yyyy-MM-dd' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="d-flex w-25 flex-column h-100 pe-2">
                                <div class="d-flex w-100 h-100">
                                    <div class="d-flex w-50 p-2">
                                        <div class="d-flex flex-column block w-100 h-100 justify-content-center align-items-center"><i class="fa-solid fa-building-columns"></i>{{ character.financial.bank }} </div>
                                    </div>
                                    <div class="d-flex w-50 p-2">
                                        <div class="d-flex flex-column block w-100 h-100 justify-content-center align-items-center"><i class="fa-solid fa-wallet"></i>{{character.financial.cash}}</div>
                                    </div>
                                </div>
                                <div class="d-flex w-100 h-100">
                                    <div class="d-flex w-50 p-2">
                                        <div class="d-flex flex-column block w-100 h-100 justify-content-center align-items-center"><i class="fa-solid fa-heart"></i>{{character.status.health}}</div>
                                    </div>
                                    <div class="d-flex w-50 p-2">
                                        <div class="d-flex flex-column block w-100 h-100 justify-content-center align-items-center"><i class="fa-solid fa-shield"></i>{{character.status.armor}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card map-container" *ngIf="character.location && activeCharacter === character.slot">
                        <h1>Live locatie</h1>
                        <div class="map-frame">
                            <app-map [zoomEnabled]="false" [panToLocation]="true" [blip]="blip" [coordinates]="character.location.coords"></app-map> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>