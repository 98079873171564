<div class="d-flex">
    <div class="d-flex flex-column h-100 me-3" [class.w-100]="playerList.length === 0" [class.w-65]="playerList.length > 0">
        <div class="card">
            <div class="d-flex text-center justify-content-center p-5 w-100" *ngIf="playerList.length === 0">
                <span *ngIf="!fetchingData">Er zijn geen actieve spelers.</span>
                <span *ngIf="fetchingData"><div class="loader"></div></span>
            </div>
            <table class="table table-striped w-100" *ngIf="playerList.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Naam</th>
                        <th>Activiteit</th>
                        <th>Laatste locatie</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let player of playerList; let i = index">
                        <td> {{ i + 1 }} </td>
                        <td> {{ player.character?.firstName }} {{ player.character?.lastName }} <span> <sup>{{ player.character?.nationality }}</sup></span> </td>
                        <td *ngIf="player.character?.activity"> {{ player.character?.activity! | activityNameMap }} </td>
                        <td *ngIf="!player.character?.activity"> {{ 'roaming' | activityNameMap }} </td>
                        <td width="100%" *ngIf="player.character?.location"> {{ player.character?.location?.name?.streetName }}<span *ngIf="player.character?.location?.name?.crossingRoad">, {{ player.character?.location?.name?.crossingRoad }}</span></td>
                        <td width="100%" *ngIf="!player.character?.location">Unknown</td>
                        <td>
                            <div class="d-flex">
                                <button class="w-50 btn btn-primary justify-content-center" routerLink="/admin/players/inspect/{{player.userData.ids.userId}}"><i class="fa-solid fa-magnifying-glass"></i></button>
                                <button class="w-50 btn btn-danger justify-content-center" (click)="kickPlayer(player.userData.ids.userId)">
                                    <i class="fa-solid fa-person-to-door" *ngIf="!pendingKicks.includes(player.userData.ids.userId)"></i>
                                    <div class="loader" *ngIf="pendingKicks.includes(player.userData.ids.userId)"></div>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="d-flex justify-content-end flex-column w-35 h-100" *ngIf="playerList.length > 0">
        <div class="widgets d-flex w-100">
            <div class="card map-container mb-2">
                <div class="map-frame">
                    <app-map [zoomEnabled]="true" [panToLocation]="true" [blipList]="blipList" (clickedMarker)="handleClickedMarker($event)"></app-map>
                </div>
            </div>
        </div>
    </div>
</div>