<div class="card border-left-info h-100 py-2 me-3">
    <div class="card-body">
        <div class="row no-gutters align-items-center">
            <div class="col-auto icon-bearer">
                <i class="fa-duotone fa-users"></i>
            </div>
            <div class="col mr-2">
                <div class="row no-gutters align-items-center">
                    <div class="col-auto ps-0">
                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{ playerList.length }}</div>
                    </div>
                </div>
                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Actieve Spelers</div>
            </div>
        </div>
    </div>
</div>