/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
**************************************************/
import { Component, OnInit } from '@angular/core';
import { DatabaseCharacter, DatabasePlayer, Player } from '../../../../../../../library/interfaces/player';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../public/services/api.service';
import { CommonModule } from '@angular/common';
import { MapComponent } from '../../../../public/components/map/map.component';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { SocketUpdateMessage } from '../../../../../../../library/interfaces/socket';
import { DurationTransformPipe } from '../../../../pipes/duration-transform.pipe';
import { ActivityNameMapPipe } from '../../../../pipes/activity-name-map.pipe';

@Component({
  selector: 'app-inspect',
  standalone: true,
  imports: [ CommonModule, MapComponent, DurationTransformPipe, ActivityNameMapPipe ],
  templateUrl: './inspect.component.html',
  styleUrl: './inspect.component.scss'
})
export class InspectComponent implements OnInit {
  // @ts-ignore
  public player: DatabasePlayer;
  // @ts-ignore
  public activeCharacter: number;
  public userId: number = -1;
  public playerFound = false;
  public loading = true;
  public pendingKick = false;
  public userHasCharacters = false;
  public blip = 409;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private websocketService: WebsocketService
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
      switch(update.type){
        case 'players':
            const players: Player[] = update.data;
            const player = players.find((player) => player.userData.ids.userId === this.userId);
            if(player && player.character){
              this.activeCharacter = player.character.slot;
              this.player.characters = this.moveToStart(this.player.characters, this.activeCharacter);
              this.player.characters[this.activeCharacter].state = player.state;
              this.player.characters[this.activeCharacter].location = player.character.location;
              this.blip = player.state?.inVehicle ? 225 : 409;
            } else {
              this.player.activeCharacter = -1;
            }
          break;
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.userId = Number(params['userId']);
      await this.getPlayerDetails(this.userId);
    });
  }

  private handlePlayerData(player: DatabasePlayer) {
    this.player = player;
    this.playerFound = true;
    if(this.player.characters.length > 0){
      this.userHasCharacters = true;
      if(this.player.activeCharacter > -1){
        this.activeCharacter = this.player.characters.filter((char) => char.slot === this.player.activeCharacter)[0].slot;
        this.player.characters = this.moveToStart(this.player.characters, this.activeCharacter);
        this.blip = this.player.characters[this.activeCharacter].state?.inVehicle ? 225 : 409;
      }
    }
  }

  private moveToStart(array: DatabaseCharacter[], slot: number): DatabaseCharacter[] {
    // Find the index of the item with the given slot
    const index = array.findIndex(item => item.slot === slot);
  
    // If the item is found
    if (index !== -1) {
      // Remove the item from its current position
      const [item] = array.splice(index, 1);
      // Insert the item at the start of the array
      array.unshift(item);
    }
  
    return array;
  }

  private async getPlayerDetails(userId: number): Promise<void> {
    const playerCall = await this.apiService.call('/players/get/by-user-id/' + userId);
    if(playerCall.success){
      this.handlePlayerData(playerCall.data);
    }

    setTimeout(() => {
      this.getPlayerDetails(userId);
    }, 60 * 1000);

    this.loading = false;
  }
}
