/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component } from '@angular/core';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { SocketUpdateMessage } from '../../../../../../../library/interfaces/socket';
import { Player } from '../../../../../../../library/interfaces/player';
import { ApiService } from '../../../../public/services/api.service';
import { DataService } from '../../../../public/services/data.service';

@Component({
  selector: 'app-active-players',
  standalone: true,
  imports: [],
  templateUrl: './active-players.component.html',
  styleUrl: './active-players.component.scss'
})
export class ActivePlayersComponent {
  public playerList: Player[] = [];

  constructor(
    private websocketService: WebsocketService,
    private datasService: DataService
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
        switch(update.type){
          case 'players':
              this.playerList = update.data;
            break;
        }
    });
  }

  async ngOnInit(): Promise<void> {
    this.playerList = await this.datasService.getActivePlayers();
  }
}