<div class="panel">
  <h3 class="p-4 pb-0">Algemene Voorwaarden van Vereenigde Compagnie</h3>
  <div class="p-4">
    <h1>1. Definities</h1>
    <ul class="circle">
        <li><strong>Gebruiker:</strong> Iedere natuurlijke of rechtspersoon die gebruik maakt van onze online server voor FiveM.</li>
        <li><strong>Diensten:</strong> Het aanbieden van een online platform voor multiplayer gaming via FiveM.</li>
        <li><strong>Staffleden:</strong> Personen die namens Vereenigde Compagnie werken en belast zijn met het beheer en de moderatie van de diensten.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>2. Toepasselijkheid</h1>
    <ul>
        <li>Deze algemene voorwaarden zijn van toepassing op alle aanbiedingen, offertes, overeenkomsten en leveringen van diensten door Vereenigde Compagnie met betrekking tot de online server voor FiveM.</li>
        <li>Door gebruik te maken van onze diensten accepteert de gebruiker deze algemene voorwaarden.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>3. Gebruik van Diensten</h1>
    <ul>
        <li>De gebruiker dient zich te houden aan de regels en voorschriften die van toepassing zijn op het gebruik van onze online server voor FiveM.</li>
        <li>Misbruik van systemen, waaronder hacken, cheating, exploitatie van bugs, en andere vormen van misbruik, is ten strengste verboden.</li>
        <li>Gebruikers dienen zich te gedragen volgens de gedragscode zoals opgesteld door Vereenigde Compagnie. Hieronder valt respectvol gedrag naar medegebruikers en staffleden.</li>
        <li>Vereenigde Compagnie behoudt zich het recht voor om gebruikers de toegang tot de diensten te ontzeggen, zonder voorafgaande kennisgeving of compensatie.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>4. Aansprakelijkheid</h1>
    <ul>
        <li>Vereenigde Compagnie stelt zich nergens voor aansprakelijk, tenzij wettelijk anders bepaald. Gebruik van onze diensten is geheel op eigen risico van de gebruiker.</li>
        <li>Betalingen en betalingsgegevens worden verwerkt door een externe partner. Vereenigde Compagnie is niet verantwoordelijk voor enige schade of verlies veroorzaakt door deze externe partij.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>5. Intellectueel Eigendom</h1>
    <ul>
        <li>Alle intellectuele eigendomsrechten met betrekking tot onze diensten, inclusief maar niet beperkt tot software, merken, logo's en content, zijn eigendom van Vereenigde Compagnie.</li>
        <li>Het is niet toegestaan om zonder voorafgaande schriftelijke toestemming van Vereenigde Compagnie gebruik te maken van onze intellectuele eigendommen.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>6. Betalingsvoorwaarden</h1>
    <ul>
        <li>Betaling voor onze diensten geschiedt volgens de voorwaarden en tarieven zoals aangegeven door de externe partner.</li>
        <li>Vereenigde Compagnie is niet verantwoordelijk voor problemen met de externe partner.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>7. Geschillenbeslechting</h1>
    <ul>
        <li>Geschillen met betrekking tot onze diensten worden expliciet beoordeeld door staffleden van Vereenigde Compagnie. Hun oordeel is bindend en definitief.</li>
        <li>Indien een geschil niet opgelost kan worden door onze staffleden, kan dit geschil worden voorgelegd aan de bevoegde rechter in Nederland.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>8. Wijzigingen aan de Algemene Voorwaarden</h1>
    <ul>
        <li>Vereenigde Compagnie behoudt zich het recht voor om de algemene voorwaarden te wijzigen. Gewijzigde voorwaarden worden gepubliceerd op onze website.</li>
        <li>Gebruikers worden actief geïnformeerd over wijzigingen in de algemene voorwaarden.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>9. Toepasselijk Recht</h1>
    <ul class="circle">
        <li>Op deze algemene voorwaarden is het Nederlands recht van toepassing.</li>
    </ul>
    <hr class="mt-5 mb-3" />
    <h1>10. Contactgegevens</h1>
    <ul class="circle">
        <li>Voor alle vragen omtrent de algemene voorwaarden kunt u contact opnemen met <a href="mailto:support@vcrp.nl">support&#64;vcrp.nl</a>.</li>
    </ul>
  </div>
</div>