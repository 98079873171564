/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component, OnInit } from '@angular/core';
import { DatabaseVehicle } from '../../../../../../../library/interfaces/vehicle';
import { CommonModule } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { ApiService } from '../../../../public/services/api.service';
import { DecimalNumberPipe } from '../../../../pipes/decimal-number.pipe';

@Component({
  selector: 'app-vehicles-registered',
  standalone: true,
  imports: [CommonModule, DecimalNumberPipe],
  templateUrl: './vehicles-registered.component.html',
  styleUrl: './vehicles-registered.component.scss'
})
export class VehiclesRegisteredComponent implements OnInit {
  public vehicleList: DatabaseVehicle[] = [];

  constructor(
    private clipboard: Clipboard,
    private apiService: ApiService
  ) {}

  async ngOnInit(): Promise<void> {
    const activeVehiclesCall = await this.apiService.call('/vehicles/get/registered');
    if(activeVehiclesCall.success){
      this.vehicleList = activeVehiclesCall.data;
    }
  }

  public copyValue(value: any){
    this.clipboard.copy(value);
  }
}
