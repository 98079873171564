/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
**************************************************/
import { Component, OnInit } from '@angular/core';
import { NavigationComponent } from '../navigation/navigation.component';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../../library/environment/environment';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, NavigationComponent, RouterLink, RouterLinkActive],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  public isAuthenticated: boolean = false;
  public avatar = '';

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    private apiService: ApiService
  ) {
    this.authService.isAuthenticated$.subscribe((data: any) => { this.isAuthenticated = data; });
  }

  public isStaff(): boolean {
    const userRoles = JSON.parse(localStorage.getItem('roles') as string);
    return userRoles.includes('staff');
  }

  public isAdmin(): boolean {
    const userRoles = JSON.parse(localStorage.getItem('roles') as string);
    return userRoles.includes('admin');
  }

  ngOnInit(): void {
    this.avatar = this.userService.getAvatar();
  }

  public async promptLogin(){
    window.location.href = environment.discord.oauth;
  }

  public gameConnect(): void {
    this.apiService.gameConnect();
  }

  public async logout(){
    this.router.navigate(['logout']);
  }
}
