<div class="loader-wrapper" *ngIf="loading">
    <div class="loader"></div>
</div>
<div class="d-flex" *ngIf="!loading">
    <div class="d-flex w-100 h-100" *ngIf="!lobby">
        <div class="card w-100 p-4">
            <h1>Speler niet gevonden</h1>
            <h5>UUID: {{ lobbyId }} is niet gevonden in de actieve wedstrijden lijst.</h5>
        </div>
    </div>
    <div class="d-flex w-100 flex-column h-100" *ngIf="lobby">
        <div class="d-flex w-100 mb-2">
            <div class="card me-2 w-25">
                <h1 class="p-3 mb-0">{{ lobby.name }}</h1>
                <div class="map">
                    <div class="d-flex w-100 map-image pe-2 ps-2">
                        <img src="assets/img/paintball/maps/{{ lobby.map.mapName }}.jpg" alt="{{ lobby.map.mapName }}" class="w-100" />
                    </div>
                    <hr />
                    <h5 class="p-2">{{ lobby.map.name }}</h5>
                    <table class="table table-borderless table-striped w-100">
                        <tbody class="w-100">
                            <tr>
                                <td>ID</td>
                                <td>{{ lobby.uuid }}</td>
                            </tr>
                            <tr>
                                <td>Mode</td>
                                <td>{{ lobby.mode.name }}</td>
                            </tr>
                            <tr>
                                <td>Dagdeel</td>
                                <td>{{ lobby.time | dayNight }}</td>
                            </tr>
                            <tr>
                                <td>Weer</td>
                                <td>{{ lobby.weather | weatherTranslate }}</td>
                            </tr>
                            <tr>
                                <td>Spelers</td>
                                <td>{{ lobby.players?.length }}</td>
                            </tr>
                            <tr>
                                <td>Doelstelling</td>
                                <td>{{ lobby.target }} <span *ngIf="lobby.mode.targetType === 'score'">kills</span><span *ngIf="lobby.mode.targetType === 'time'">minuten</span></td>
                            </tr>
                            <!-- <tr>
                                <td class="ps-2 pe-0"><i class="fa-fw fa-solid fa-users"></i>Karakters</td>
                                <td>{{player.characters.length}}</td>
                            </tr>
                            <tr>
                                <td class="ps-2 pe-0"><i class="fa-fw fa-solid fa-list"></i>IDs</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="ps-4 pe-0"><i class="fa-fw fa-solid fa-id-badge"></i>VCRP User ID</td>
                                <td><small>{{ player.ids.userId }}</small></td>
                            </tr>
                            <tr>
                                <td class="ps-4 pe-0"><i class="fa-fw fa-brands fa-discord"></i>Discord</td>
                                <td><small>{{ player.ids.discord }}</small></td>
                            </tr>
                            <tr>
                                <td class="ps-2 pe-0"><i class="fa-fw fa-solid fa-calendar-circle-user"></i>Joined</td>
                                <td>{{player.dateInsert | date : 'yyyy-MM-dd'}}</td>
                            </tr>
                            <tr>
                                <td class="ps-2 pe-0"><i class="fa-fw fa-solid fa-calendar-clock"></i>Laatste update</td>
                                <td>{{player.dateLastUpdate | date : 'yyyy-MM-dd'}}</td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="flex-column flex-grow-1 me-2">
                <div class="d-flex w-100 flex-column">
                    <div class="card w-100" *ngIf="lobby?.mode?.mode!.startsWith('team_')">
                        <h1 class="p-3 d-flex align-items-center">
                            Scorebord
                        </h1>
                        <div class="d-flex w-100 p-2">
                            <div class="d-flex w-50 align-items-start">
                                <div class="d-flex w-90 flex-column justify-content-start">
                                    <h5 class="w-100 text-center">Team A</h5>
                                    <table class="table table-borderless table-striped w-100">
                                        <thead>
                                            <tr>
                                                <th>Naam</th>
                                                <th>Kills</th>
                                                <th>Deaths</th>
                                                <th>K/D</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let player of lobby.players">
                                                <tr *ngIf="player.team === 1">
                                                    <td>{{ player.name }}</td>
                                                    <td>{{ player.score }}</td>
                                                    <td>{{ player.deaths }}</td>
                                                    <td>{{ (player.score / (player.deaths + 1)) | number:'1.0-1' }}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="d-flex w-50 justify-content-end align-items-start">
                                <div class="d-flex w-90 justify-content-end flex-column">
                                    <h5 class="w-100 text-center">Team B</h5>
                                    <table class="table table-borderless table-striped w-100">
                                        <thead>
                                            <tr>
                                                <th>Naam</th>
                                                <th>Kills</th>
                                                <th>Deaths</th>
                                                <th>K/D</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let player of lobby.players">
                                                <tr *ngIf="player.team === 2">
                                                    <td>{{ player.name }}</td>
                                                    <td>{{ player.score }}</td>
                                                    <td>{{ player.deaths }}</td>
                                                    <td>{{ (player.score / (player.deaths + 1)) | number:'1.0-1' }}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card w-100" *ngIf="!lobby?.mode?.mode!.startsWith('team_')">
                        <h1 class="p-3 d-flex align-items-center">
                            Scorebord
                        </h1>
                        <div class="d-flex w-100">
                            <table class="table table-borderless table-striped w-100">
                                <thead>
                                    <tr>
                                        <th>Naam</th>
                                        <th>Kills</th>
                                        <th>Deaths</th>
                                        <th>K/D</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let player of lobby.players">
                                        <td>{{ player.name }}</td>
                                        <td>{{ player.score }}</td>
                                        <td>{{ player.deaths }}</td>
                                        <td>{{ (player.score / (player.deaths + 1)) | number:'1.0-1' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card w-100 mt-4 d-none">
                        <h1 class="p-3 d-flex align-items-center">
                            Ruwe data
                        </h1>
                        <div class="d-flex w-100">
                            <pre>{{ lobby | json }}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>