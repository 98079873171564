/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../../../library/environment/environment';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  public authenticated = false;
  public authenticating = false;
  public loginError = '';
  private oAuthCode = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private apiService: ApiService
  ) { }

  async ngOnInit(): Promise<void> {
    this.oAuthCode = this.activatedRoute?.snapshot?.queryParamMap?.get('code') as string;
    if(this.oAuthCode){
      this.authenticating = true;
      try {
        const loginAttempt = await this.authenticationService.login(this.oAuthCode);
        if(loginAttempt.success){
          this.router.navigate(['account']);
        } else {
          this.authenticating = false;
          switch(loginAttempt.reason){
            case 'user_unknown':
              this.loginError = 'Jouw Discord ID is bij ons niet bekend. Zorg ervoor dat je minstens éénmaal via het spel hebt deelgenomen voordat je via de website inlogt.'
              break;
            default:
              this.loginError = 'Er is iets misgegaan bij het inloggen.'
              console.error(`Login failed: ${loginAttempt.reason}`);
              break;
          }
        }
      } catch(e){
        this.authenticating = false;
        this.loginError = 'Kan geen verbinding maken met authenticatie server'
      }
    }
  }

  public gameConnect(): void {
    this.apiService.gameConnect();
  }

  public async promptLogin(){
    window.location.href = environment.discord.oauth;
  }
}