/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { PlayerInstance } from '../../../../../../library/interfaces/instance';
import { AgGridAngular } from 'ag-grid-angular';
import { CommonModule } from '@angular/common';
import { GridOptions } from 'ag-grid-community';
import { DataService } from '../../../public/services/data.service';
import { WebsocketService } from '../../../public/services/websocket.service';
import { SocketUpdateMessage } from '../../../../../../library/interfaces/socket';

@Component({
  selector: 'app-instances',
  standalone: true,
  imports: [CommonModule, AgGridAngular],
  templateUrl: './instances.component.html',
  styleUrl: './instances.component.scss'
})
export class InstancesComponent implements OnInit {
  @ViewChild('agGrid', { static: false }) agGrid: any;
  public instanceList: PlayerInstance[] = [];
  public fetchingData = true;

  constructor(
    private dataService: DataService,
    private websocketService: WebsocketService
  ){
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
      switch(update.type){
        case 'instance':
          this.instanceList = update.data;
          this.rowData = this.instanceList;
          if (this.agGrid && this.agGrid.api) this.agGrid.api.setRowData(this.rowData);
          break;
      }
  });
  }

  public agOptions = {
    pagination: true,
    paginationPageSize: 15,
    paginationPageSizeSelector: [5, 10, 15, 25, 50, 100, 200],
  }
  public rowData: any[] | null = [];
  public gridOptions: GridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      sortable: false,
      resizable: false
    },
    columnDefs: [
      { field: 'name' },
      { 
        field: 'players',
        headerName: '# Players in instance',
        cellRenderer: (params: any) => {
          return params.value.length;
        }
       }
    ]
  }

  async ngOnInit(): Promise<void> {
    this.instanceList = await this.dataService.getInstances();
    this.fetchingData = false;
    this.rowData = this.instanceList;
  }
}
