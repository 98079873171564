<div class="d-flex w-100" *ngIf="!user">
    <div class="d-flex w-100" *ngIf="fetching">
        <div class="panel shadow w-100">
            <h3 class="p-4 pb-0">Gegevens ophalen...</h3>
            <hr />
            <div class="d-flex p-4 justify-content-center">
                <div class="loader"></div>
            </div>
        </div>
    </div>
    <div class="d-flex w-100" *ngIf="!fetching">
        <div class="panel shadow w-100">
            <h3 class="p-4 pb-0">Fout!</h3>
            <hr />
            <div class="d-flex p-4">
                Er kunnen geen gegevens worden opgehaald.
            </div>
        </div>
    </div>
</div>
<!-- 
/************************************************
* User/Character view
*************************************************/
-->
<div class="d-flex account-container" [class.active]="selectionActive" *ngIf="user">
    <div class="sidebar d-flex flex-column me-0 me-sm-2">
        <!-- 
        /************************************************
        * Account overview
        *************************************************/
        -->
        <div class="panel shadow login mb-4 mb-sm-2 account-panel">
            <h3 class="p-4 pb-0">Account overzicht</h3>
            <hr />
            <div class="d-flex account-data p-4">
                <div class="avatar me-3">
                    <img src="{{ avatar }}"/>
                </div>
                <div class="name">
                    <h3>{{userName}}</h3>
                    <div class="roles">{{ user.roles }}</div>
                </div>
            </div>
            <div class="d-flex flex-column p-4 pt-0">
                <div class="d-flex flex-column">
                    <div class="d-flex flex-column mb-2">
                        <span class="opacity-75">Lid sinds:</span>
                        {{ user.dateInsert | date }}
                    </div>
                    <div class="d-flex flex-column mb-2">
                        <span class="opacity-75">Aantal Karakters:</span>
                        {{ user.characters.length }}
                    </div>
                    <div class="d-flex flex-column mb-2">
                        <span class="opacity-75">Totale speeltijd:</span>
                        {{ totalPlaytime | durationTransform }}
                    </div>
                </div>
            </div>
        </div>
         <!-- 
        /************************************************
        * Character list
        *************************************************/
        -->
        <div class="panel shadow mb-4 mb-sm-2 characters-panel">
            <h3 class="p-4 pb-0">Karakters</h3>
            <hr />
            <div class="d-flex flex-column">
                <div class="character in-use d-flex" *ngFor="let character of user.characters; let i = index" [class.active]="selectedCharacter == i" (click)="setSelectedCharacter(i)">
                    <div class="avatar me-3">
                        <img src="assets/img/placeholders/no_avatar.jpg"/>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex">
                            {{ character.firstName }} {{ character.lastName }}
                        </div>
                        <div class="d-flex location">
                            <span *ngIf="character.location"><i class="fa-thin fa-map-pin pe-1"></i> {{ character.location.name.streetName }}<span *ngIf="character.location.name.crossingRoad">, {{character.location.name.crossingRoad}}</span></span>
                            <span *ngIf="!character.location"><i class="fa-thin fa-map-pin pe-1"></i> Onbekend</span>
                        </div>
                        <div class="d-flex financial">
                            <span class="pe-2" *ngIf="character.financial.bank || character.financial.bank == 0"><i class="fa-thin fa-building-columns pe-1"></i> €{{ character.financial.bank }},00</span>
                            <span class="pe-2" *ngIf="character.financial.cash || character.financial.cash == 0"><i class="fa-thin fa-money-bill pe-1"></i> €{{ character.financial.cash }},00</span>
                            <span class="pe-2" *ngIf="!character.financial.bank && character.financial.bank !== 0"><i class="fa-thin fa-building-columns pe-1"></i> €0,00</span>
                            <span class="pe-2" *ngIf="!character.financial.cash && character.financial.cash !== 0"><i class="fa-thin fa-money-bill pe-1"></i> €0,00</span>
                        </div>
                    </div>
                </div>
                <div class="character empty d-flex" *ngFor="let i of [].constructor(4 - (user?.characters?.length || 0))" [class.active]="selectedCharacter == i">
                    <div class="avatar me-3">
                        <img src="assets/img/placeholders/no_avatar_alt.jpg"/>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex">
                            Vrije karakter slot
                        </div>
                        <div class="d-flex location">
                            <span><i class="fa-thin fa-map-pin pe-1"></i> Geen</span>
                        </div>
                        <div class="d-flex financial">
                            <span class="pe-2"><i class="fa-thin fa-building-columns pe-1"></i> €0,00</span>
                            <span class="pe-2"><i class="fa-thin fa-money-bill pe-1"></i> €0,00</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column character-content w-100" *ngIf="selectedCharacter > -1">
        <div class="d-flex flex-column content w-100">
            <!-- 
            /************************************************
            * Back button
            *************************************************/
             -->
            <div class="btn btn-primary mb-2 d-flex d-sm-none" (click)="resetSelectedCharacter()"><i class="fa-solid fa-arrow-left"></i> terug</div>
            <!-- 
            /************************************************
            * Character info
            *************************************************/
             -->
            <div class="panel shadow character-info login mb-4 mb-sm-2">
                <h3 class="p-4 pb-0">Karakter informatie</h3>
                <hr />
                <div class="d-flex">
                    <div class="d-none d-sm-flex flex-column">
                        <div class="avatar">
                            <img src="assets/img/placeholders/no_avatar.jpg"/>
                        </div>
                    </div>
                    <table class="table table-striped mb-0">
                        <tbody>
                            <tr>
                                <td class="w-25">Naam:</td>
                                <td>{{ user.characters[selectedCharacter].firstName }} {{ user.characters[selectedCharacter].lastName }} <sup class="nationality">{{ user.characters[selectedCharacter].nationality }}</sup></td>
                            </tr>
                            <tr>
                                <td>Geboortedatum:</td>
                                <td>{{ user.characters[selectedCharacter].birthDate | date }}</td>
                            </tr>
                            <tr>
                                <td>Speeltijd:</td>
                                <td>{{ user.characters[selectedCharacter].playTime | durationTransform }}</td>
                            </tr>
                            <tr>
                                <td>Datum aangemaakt:</td>
                                <td>{{ user.characters[selectedCharacter].dateInsert | date }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- 
            /************************************************
            * Location
            *************************************************/
             -->
            <div class="panel shadow map mb-4 mb-sm-2">
                <h3 class="p-4 pb-0">Laatst bekende locatie</h3>
                <hr />
                <div class="map-frame" [class.expand]="user.characters[selectedCharacter].location">
                    <app-map *ngIf="user.characters[selectedCharacter].location" [zoomEnabled]="false" [panToLocation]="true" [blip]="409" [coordinates]="user.characters[selectedCharacter].location.coords"></app-map>
                    <span *ngIf="!user.characters[selectedCharacter].location">
                        Locatie is onbekend
                    </span>
                </div>
            </div>
            <!-- 
            /************************************************
            * Vehicles
            *************************************************/
             -->
            <div class="panel shadow vehicles mb-4 mb-sm-2" >
                <h3 class="p-4 pb-0">Voertuigen</h3>
                <hr />
                <ng-container *ngIf="user.characters[selectedCharacter].vehicles.length === 0">
                    <div class="d-flex p-4">
                        Geen voertuigen gevonden.
                    </div>
                </ng-container>
                <ng-container *ngIf="user.characters[selectedCharacter].vehicles.length > 0">
                    <div class="d-flex vehicle flex-column flex-sm-row" *ngFor="let vehicle of user.characters[selectedCharacter].vehicles">
                        <div class="d-flex vendor-logo justify-content-center me-3">
                            <img src="assets/img/manufacturers/{{ vehicle.vendor.manufacturer | lowercase }}.svg" />
                            <div class="license">{{ vehicle.world.license }}</div>
                        </div>
                        <div class="d-flex vehicle-details flex-column w-100">
                            <div class="d-flex name">
                                <h1 class="d-flex flex-grow-1">{{ vehicle.vendor.name }}</h1>
                                <div class="swatch" [style.background-color]="'rgba(' + vehicle.colors.primary.r + ',' + vehicle.colors.primary.g + ',' + vehicle.colors.primary.b + ',' + vehicle.colors.primary.a + ')'"></div>
                            </div>
                            <div class="d-flex manufacturer">
                                <h3>{{ vehicle.vendor.manufacturer }}</h3>
                            </div>
                            <div class="d-flex build-year">
                                <h5>{{ vehicle.vendor.buildYear }}</h5>
                            </div>
                            <div class="d-flex mods">
                                Mods: {{ vehicle.mods!.mods!.length }}
                            </div>
                            <div class="d-flex mods">
                                Brandstofpeil: {{ vehicle.metrics.fuel }}
                            </div>
                            <div class="d-flex mods">
                                Oliepeil: {{ vehicle.metrics.oil }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- <div class="panel shadow login mb-4 mb-sm-2">
                <pre>{{ user.characters[selectedCharacter] | json }}</pre>
            </div> -->
        </div>
    </div>
</div>