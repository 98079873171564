/************************************************
*   :---:.                     .::::..          *
*   :@@@@@%-               :+#@@@@@@@@@%*-      *
*    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
*     -@@@@@@-          #@@@@@@@%***#@@@@=      *
*      =@@@@@@.        #@@@@@%-       :=        *
*       =@@@@@%.      #@@@@@#                   *
*        +@@@@@%.    #@@@@@*                    *
*         +@@@@@@.  %@@@@@*.                    *
*           @@@@@%:%@@@@@*.%%.                  *
*            @@@@@@@@@@@# %@@@+:     .=+.       *
*            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
*            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
*             :@@@@@@@:     :+#@@@@@@@%*-       *
*              ......                           *
*   ┌────────────────────────────────────────┐  *
*   │         Vereenigde Compagnie           │  *
*   │             fiveM server               │  *
*   ├────────────────────────────────────────┤  *
*   │                vcrp.nl                 │  *
*   └────────────────────────────────────────┘  *
*************************************************/
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../public/services/api.service';
import { Resource } from '../../../../../../library/interfaces/resource';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-resources',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './resources.component.html',
  styleUrl: './resources.component.scss'
})
export class ResourcesComponent implements OnInit {
  public resourceList: Resource[] = [];
  public fetchingData = true;
  public resourceDelayingUpdate: string[] = [];
  
  constructor(
    private apiService: ApiService
  ){}

  public async refreshData(): Promise<void> {
    const resourceData = await this.apiService.call('/resources/list');

    if(resourceData.success){
      this.resourceList = resourceData.data;
    }

    this.fetchingData = false;
  }

  public async updateResource(resource: Resource, direction: 'start' | 'stop' | 'restart'): Promise<void> {
    this.resourceDelayingUpdate.push(resource.name);
    // @ts-ignore
    this.resourceList = this.resourceList.map((resourceItem) => {
        if (resourceItem.name === resource.name) {
            switch(direction){
              case 'start':
                resourceItem.state = 'starting';
                break;
              case 'stop':
                resourceItem.state = 'stopping';
                break;
              case 'restart':
                resourceItem.state = 'starting';
                break;
            }
            return resourceItem;
        } else {
          return resourceItem;
        }
    });
    const resourceUpdateCall = await this.apiService.call(`/resources/update/${resource.name}/${direction}`);
    if(resourceUpdateCall.success){
      this.resourceList = resourceUpdateCall.data;
    }

    this.resourceDelayingUpdate = this.resourceDelayingUpdate.filter((resourceName) => resourceName !== resource.name);
  }

  ngOnInit(): void {
    this.refreshData();
  }
}
