/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { ApiService } from '../../../../public/services/api.service';
import { DatabaseCharacter } from '../../../../../../../library/interfaces/player';
import { DurationTransformPipe } from '../../../../pipes/duration-transform.pipe';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'app-registered',
  standalone: true,
  imports: [ CommonModule, DurationTransformPipe, RouterLink ],
  templateUrl: './registered.component.html',
  styleUrl: './registered.component.scss'
})
export class RegisteredComponent {
  public playerList: DatabaseCharacter[] = [];

  constructor(
    private clipboard: Clipboard,
    private apiService: ApiService
  ) {}

  async ngOnInit(): Promise<void> {
    const registerdPlayersCall = await this.apiService.call('/players/get-registered');
    if(registerdPlayersCall.success){
      this.playerList = registerdPlayersCall.data;
    }
  }

  public copyValue(value: any){
    this.clipboard.copy(value);
  }
}