/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component, OnInit } from '@angular/core';
import { LiveBlip, MapComponent } from '../../../../public/components/map/map.component';
import { Coordinates } from '../../../../../../../library/interfaces/location';
import { ApiService } from '../../../../public/services/api.service';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { SocketUpdateMessage } from '../../../../../../../library/interfaces/socket';
import { Player } from '../../../../../../../library/interfaces/player';
import { Vehicle, VehicleType } from '../../../../../../../library/interfaces/vehicle';
import { DataService } from '../../../../public/services/data.service';

@Component({
  selector: 'app-live-map',
  standalone: true,
  imports: [ MapComponent ],
  templateUrl: './live-map.component.html',
  styleUrl: './live-map.component.scss'
})
export class LiveMapComponent implements OnInit {
  public blipList: LiveBlip[] = [];
  public vehicleList: Vehicle[] = [];
  public playerList: Player[] = [];

  constructor(
    private websocketService: WebsocketService,
    private dataService: DataService
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
        switch(update.type){
          case 'players':
            const playerList: Player[] = update.data;
            this.playerList = playerList;
            this.addBlips(this.playerList, this.vehicleList);
            break;
          case 'vehicles':
            const vehicleList: Vehicle[] = update.data;
            this.vehicleList = vehicleList;
            this.addBlips(this.playerList, this.vehicleList);
            break;
        }
    });
  }

  private addBlips(playerList: Player[] = [], vehicleList: Vehicle[] = []): void {
    const newBlipList: LiveBlip[] = [];
    playerList.forEach((player: Player) => {
      if(player.state.inVehicle){
        const vehicleIndex = vehicleList.findIndex((vehicle: Vehicle) => vehicle.world.networkEntity === player.character?.entities?.vehicle);
        if(vehicleIndex && this.vehicleList[vehicleIndex] && this.vehicleList[vehicleIndex].hasOwnProperty('world')){
          this.vehicleList[vehicleIndex].world.license = `${player.character?.firstName} ${player.character?.lastName} [${this.vehicleList[vehicleIndex].world.license}]`;
        }
      } else {
        newBlipList.push({
          name: `${player.character?.firstName} ${player.character?.lastName}`,
          coordinates: player.character?.location.coords as Coordinates,
          blipSprite: 280,
          type: 'player',
          id: player.userData.ids.userId as number
        });
      }
    });
    vehicleList.forEach((vehicle: Vehicle) => {
      newBlipList.push({
        name: `${vehicle.world.license} (${vehicle.world.owner.userId})`,
        coordinates: vehicle.world.location as Coordinates,
        blipSprite: this.mapVehicleTypeToBlip(vehicle.vendor.type),
        type: 'vehicle',
        id: vehicle.world.uuid
      });
    });

    this.blipList = newBlipList;
  }

  private mapVehicleTypeToBlip(vehicleType: VehicleType){
    switch(vehicleType){
      case 'car':
        return 225;
      case 'motor':
        return 226;
      case 'other':
        return 1;
      case 'helicopter':
        return 43;
      case 'trailer':
        return 479;
    }
  }

  private async fetchInitData(){
    this.playerList = await this.dataService.getActivePlayers();
    this.vehicleList = await this.dataService.getActiveVehicles();

    this.addBlips(this.playerList, this.vehicleList);
  }

  async ngOnInit(): Promise<void> {
    this.fetchInitData();
  }
}
