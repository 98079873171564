/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationTransform',
  standalone: true
})
export class DurationTransformPipe implements PipeTransform {
  transform(minutes: number): string {
    if (isNaN(minutes) || minutes < 0) {
      return 'Invalid duration';
    }

    const minutesInHour = 60;
    const hoursInDay = 24;
    const daysInWeek = 7;
    const daysInMonth = 30; // Approximate days in a month

    const totalMinutes = Math.abs(minutes);
    const totalHours = Math.floor(totalMinutes / minutesInHour);
    const remainingMinutes = totalMinutes % minutesInHour;
    const totalDays = Math.floor(totalHours / hoursInDay);
    const remainingHours = totalHours % hoursInDay;
    const totalWeeks = Math.floor(totalDays / daysInWeek);
    const remainingDays = totalDays % daysInWeek;
    const totalMonths = Math.floor(totalDays / daysInMonth);
    const remainingDaysInMonth = totalDays % daysInMonth;

    const parts = [];

    if (totalMonths > 0) {
      parts.push(`${totalMonths}mo`);
    }
    if (totalWeeks > 0) {
      parts.push(`${totalWeeks}w`);
    }
    if (remainingDaysInMonth > 0) {
      parts.push(`${remainingDaysInMonth}d`);
    }
    if (remainingHours > 0) {
      parts.push(`${remainingHours}h`);
    }
    if (remainingMinutes > 0) {
      parts.push(`${remainingMinutes}m`);
    }

    if (parts.length === 0) {
      return '0m';
    }

    return parts.join(', ');
  }
}
