/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Injectable } from '@angular/core';
import { Player } from '../../../../../library/interfaces/player';
import { PlayerInstance } from '../../../../../library/interfaces/instance';
import { Vehicle } from '../../../../../library/interfaces/vehicle';
import { ApiService } from './api.service';
import { TimeWeatherStatus } from '../../../../../library/interfaces/weather';
import { News } from '../../../../../library/interfaces/news';
import { PaintballLobby } from '../../../../../library/interfaces/paintball';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private cacheTime = 3 * 1000;
  private activePlayers: Player[] = [];
  private activeVehicles: Vehicle[] = [];
  private paintballLobbies: PaintballLobby[] = [];
  private timeWeatherStatus: TimeWeatherStatus | null = null;
  private instances: PlayerInstance[] = [];
  private news: News[] = [];
  private fetchDates = {
    players: 0,
    vehicles: 0,
    timeWeather: 0,
    instances: 0,
    news: 0,
    paintball: 0
  };
  private pendingCalls: string[] = [];

  constructor(
    private apiService: ApiService
  ) {}

  public async getTimeWeatherStatus(): Promise<TimeWeatherStatus| null> {
    const timeDiff = Math.abs(this.fetchDates.timeWeather - (Date.now() - this.cacheTime));

    // Check if a call is already pending, and wait for it if so
    if(this.pendingCalls.includes('time-weather')){
      setTimeout(async () => {
        return await this.getTimeWeatherStatus();
      }, 1000);
    }

    if(timeDiff > 10000 || this.timeWeatherStatus === null){
      this.pendingCalls.push('time-weather');
      const call = await this.apiService.call('/time-weather/get');
      if(call && call.success){
        this.timeWeatherStatus = call.data;
        this.fetchDates.timeWeather = Date.now();
        this.pendingCalls = this.pendingCalls.filter(pendingCall => pendingCall !== 'time-weather');
      }
    }

    return this.timeWeatherStatus;
  }

  public async getNews(): Promise<News[]> {
    const timeDiff = Math.abs(this.fetchDates.news - (Date.now() - this.cacheTime));
    if(this.pendingCalls.includes('news')){
      setTimeout(async () => {
        return await this.getNews();
      }, 1000);
    }

    if(timeDiff > 10000 || this.news === null){
      this.pendingCalls.push('news');
      const call = await this.apiService.call('/news');
      if(call && call.success){
        this.news = call.data;
        this.fetchDates.news = Date.now();
        this.pendingCalls = this.pendingCalls.filter(pendingCall => pendingCall !== 'news');
      }
    }

    return this.news;
  }

  public async getInstances(): Promise<PlayerInstance[]> {
    const timeDiff = Math.abs(this.fetchDates.instances - (Date.now() - this.cacheTime));

    // Check if a call is already pending, and wait for it if so
    if(this.pendingCalls.includes('instances')){
      setTimeout(async () => {
        return await this.getInstances();
      }, 1000);
    }

    if(timeDiff > 10000 || this.instances === null){
      this.pendingCalls.push('instances');
      const call = await this.apiService.call('/instances/get');
      if(call && call.success){
        this.instances = call.data;
        this.fetchDates.instances = Date.now();
        this.pendingCalls = this.pendingCalls.filter(pendingCall => pendingCall !== 'instances');
      }
    }

    return this.instances;
  }

  public async getActivePlayers(): Promise<Player[]> {
    const timeDiff = Math.abs(this.fetchDates.players - (Date.now() - this.cacheTime));

    // Check if a call is already pending, and wait for it if so
    if(this.pendingCalls.includes('active-players')){
      setTimeout(async () => {
        return await this.getActivePlayers();
      }, 1000);
    }

    if(timeDiff > 10000 || this.activePlayers.length === 0){
      this.pendingCalls.push('active-players');
      const call = await this.apiService.call('/players/get-active');
      if(call && call.success){
        this.activePlayers = call.data;
        this.fetchDates.players = Date.now();
        this.pendingCalls = this.pendingCalls.filter(pendingCall => pendingCall !== 'active-players');
      }
    }
    
    return this.activePlayers;
  }

  public async getActiveVehicles(): Promise<Vehicle[]> {
    const timeDiff = Math.abs(this.fetchDates.vehicles - (Date.now() - this.cacheTime));

    // Check if a call is already pending, and wait for it if so
    if(this.pendingCalls.includes('paintball-lobbies')){
      setTimeout(async () => {
        return await this.getActiveVehicles();
      }, 1000);
    }

    if(timeDiff > 10000 || this.activeVehicles.length === 0){
      this.pendingCalls.push('active-vehicles');
      const call = await this.apiService.call('/vehicles/get/active');
      if(call && call.success){
        this.activeVehicles = call.data;
        this.fetchDates.vehicles = Date.now();
        this.pendingCalls = this.pendingCalls.filter(pendingCall => pendingCall !== 'active-vehicles');
      }
    }

    return this.activeVehicles;
  }

  public async getPaintballLobbies(): Promise<PaintballLobby[]> {
    const timeDiff = Math.abs(this.fetchDates.vehicles - (Date.now() - this.cacheTime));

    // Check if a call is already pending, and wait for it if so
    if(this.pendingCalls.includes('paintball-lobbies')){
      setTimeout(async () => {
        return await this.getPaintballLobbies();
      }, 1000);
    }

    if(timeDiff > 10000 || this.activeVehicles.length === 0){
      this.pendingCalls.push('paintball-lobbies');
      const call = await this.apiService.call('/paintball/get/active');
      if(call && call.success){
        this.paintballLobbies = call.data;
        this.fetchDates.paintball = Date.now();
        this.pendingCalls = this.pendingCalls.filter(pendingCall => pendingCall !== 'paintball-lobbies');
      }
    }

    return this.paintballLobbies;
  }
}
