/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
**************************************************/
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { CommonModule } from '@angular/common';
import { News } from '../../../../../../library/interfaces/news';
import { DataService } from '../../services/data.service';
import { HtmlMarkdownPipe } from '../../../pipes/html-markdown.pipe';

@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [ CommonModule, HtmlMarkdownPipe ],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss'
})
export class HomepageComponent implements OnInit {
  public fetching = {
    stats: true,
    news: true
  };
  public stats = {
    discord: {
      online: '-',
      total: '-'
    },
    players: {
      online: '-',
      total: '-'
    }
  }

  public news: News[] = [];

  constructor(
    private apiService: ApiService,
    private dataService: DataService
  ) {}

 ngOnInit(): void {
   this.getNews();
   setTimeout(() => {
    this.fetching.stats = false;
   }, 1500);
 }

  public async getNews(){
    this.news = await this.dataService.getNews();
    this.fetching.news = false;
  }

  public gameConnect(): void {
    this.apiService.gameConnect();
  }
}
