import { Pipe, PipeTransform } from '@angular/core';
import { CharacterActivity } from '../../../../library/interfaces/player';

@Pipe({
  name: 'activityNameMap',
  standalone: true
})
export class ActivityNameMapPipe implements PipeTransform {

  transform(name: CharacterActivity): string {
    switch(name){
      case 'roaming': return 'Rondzwerven';
      case 'job': return 'Aan het werk';
      case 'paintball': return 'Paintball';
      default: return 'Rondzwerven';
    }
  }

}
