<div class="card mb-3 border-left-info w-100">
    <h1>Tijd & Weer <button routerLink="/admin/weather"><i class="fa-light fa-sliders"></i></button></h1>
    <div class="d-flex justify-content-center w-100" *ngIf="weatherStatus === null">
        <div class="loader"></div>
    </div>

    <div class="card-body d-flex justify-content-center w-100" *ngIf="weatherStatus !== null">
        <div class="d-flex w-50 time-container justify-content-center">
            <div class="d-flex flex-column">
                <div class="d-flex time">
                    <div class="hour">{{ weatherStatus.time.hour | niceTime }}</div>
                    <div class="minute">{{ weatherStatus.time.minute | niceTime }}</div>
                </div>
                <div class="d-flex day">
                    <div class="weekday">{{ weatherStatus.day | weekDay }}</div>
                </div>
            </div>
        </div>
        <div class="d-flex w-50 weather-container justify-content-center">
            <div class="d-flex flex-column">
                <div class="d-flex time">
                    <div class="forecast">{{ weatherStatus.forecast.type }}</div>
                </div>
                <div class="d-flex day">
                    <div class="temp">{{ weatherStatus.forecast.temperature }}°C</div>
                </div>
            </div>
        </div>
    </div>
</div>