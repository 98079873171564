<div class="navigation d-flex flex-column h-100">
  <div class="navigation-wrapper flex-grow-1">
    <div class="d-flex logo justify-content-center">
        <img src="assets/img/logo.svg" />
    </div>
    <nav>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
              <h2 class="accordion-header">
                  <button routerLink="/admin" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}" class="accordion-button collapsed no-icon" type="button">
                      <i class="me-2 text-end fa-fw fa-solid fa-house"></i> Dashboard
                  </button>
              </h2>
          </div>
        </div>
        <div class="header">Server</div>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
                <button routerLink="/admin/resources" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-light fa-cube"></i> Resources
                </button>
            </h2>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
                <button routerLink="/admin/instances" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-solid fa-person-from-portal"></i> Instances
                </button>
            </h2>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
                <button routerLink="/admin/wip" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-solid fa-money-bill-transfer"></i> Transacties
                </button>
            </h2>
          </div>
        </div>
        <div class="header">Algemeen</div>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button routerLink="/admin/players/active" [routerLinkActive]="'active'" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#player-menu" aria-expanded="false" aria-controls="flush-collapseOne">
                  <i class="me-2 text-end fa-fw fa-solid fa-users"></i> Spelers
              </button>
            </h2>
            <div id="player-menu" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <h2 class="accordion-header">
                <button routerLink="/admin/players/active" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-light fa-circle"></i> Actieve spelers
                </button>
              </h2>
              <h2 class="accordion-header">
                <button routerLink="/admin/players/registered" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-light fa-circle"></i> Geregistreerde spelers
                </button>
              </h2>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button routerLink="/admin/vehicles/active" [routerLinkActive]="'active'" class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#vehicle-menu" aria-expanded="false" aria-controls="flush-collapseOne">
                <i class="me-2 text-end fa-fw fa-duotone fa-cars"></i> Voertuigen
              </button>
            </h2>
            <div id="vehicle-menu" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <h2 class="accordion-header">
                <button routerLink="/admin/vehicles/active" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-light fa-circle"></i> Actieve voertuigen
                </button>
              </h2>
              <h2 class="accordion-header">
                <button routerLink="/admin/vehicles/models" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-light fa-circle"></i> Alle modellen
                </button>
              </h2>
              <h2 class="accordion-header">
                <button routerLink="/admin/vehicles/registered" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-light fa-circle"></i> Geparkeerde voertuigen
                </button>
              </h2>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
                <button routerLink="/admin/wip" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-duotone fa-clouds"></i> Tijd & Weer
                </button>
            </h2>
          </div>
        </div>
        <div class="header">Activiteiten</div>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
                <button routerLink="/admin/paintball/active" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-solid fa-swords"></i> Paintball
                </button>
            </h2>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
                <button routerLink="/admin/wip" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-solid fa-briefcase-blank"></i> Werk
                </button>
            </h2>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
                <button routerLink="/admin/wip" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-regular fa-gem"></i> Casino
                </button>
            </h2>
          </div>
        </div>
        <div class="header">Hulpdiensten</div>
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
                <button routerLink="/admin/wip" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-solid fa-user-police"></i> Politie
                </button>
            </h2>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
                <button routerLink="/admin/wip" [routerLinkActive]="'active'" class="accordion-button collapsed no-icon" type="button">
                  <i class="me-2 text-end fa-fw fa-solid fa-user-doctor"></i> Ambulance
                </button>
            </h2>
          </div>
        </div>
    </nav>
  </div>
  <div class="d-flex socket-status h-100 align-items-end">
    <div class="d-flex w-100 p-3 justify-content-center">
      <div class="text-success" *ngIf="isConnected">
        <i class="me-2 text-end fa-fw fa-solid fa-circle"></i> server verbonden
      </div>
      <div class="text-warning" *ngIf="!isConnected">
        <i class="me-2 text-end fa-fw fa-regular fa-circle"></i> server niet verbonden
      </div>
    </div>
  </div>
</div>