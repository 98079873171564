/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Pipe, PipeTransform } from '@angular/core';
import { WeatherType } from '../../../../library/interfaces/weather';


@Pipe({
  name: 'weatherTranslate',
  standalone: true
})
export class WeatherTranslatePipe implements PipeTransform {

  private translations: { [key in WeatherType]: string } = {
    EXTRASUNNY: 'Zeer zonnig',
    CLEAR: 'Helder',
    FOGGY: 'Mistig',
    OVERCAST: 'Bewolkt',
    CLOUDS: 'Wolken',
    CLEARING: 'Opleverend',
    RAIN: 'Regen',
    THUNDER: 'Onweer',
    SNOW: 'Sneeuw',
    BLIZZARD: 'Sneeuwstorm',
    SNOWLIGHT: 'Lichte sneeuw'
  };

  transform(value: WeatherType): string {
    return this.translations[value] || value;
  }
}