/************************************************
*   :---:.                     .::::..          *
*   :@@@@@%-               :+#@@@@@@@@@%*-      *
*    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
*     -@@@@@@-          #@@@@@@@%***#@@@@=      *
*      =@@@@@@.        #@@@@@%-       :=        *
*       =@@@@@%.      #@@@@@#                   *
*        +@@@@@%.    #@@@@@*                    *
*         +@@@@@@.  %@@@@@*.                    *
*           @@@@@%:%@@@@@*.%%.                  *
*            @@@@@@@@@@@# %@@@+:     .=+.       *
*            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
*            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
*             :@@@@@@@:     :+#@@@@@@@%*-       *
*              ......                           *
*   ┌────────────────────────────────────────┐  *
*   │         Vereenigde Compagnie           │  *
*   │             fiveM server               │  *
*   ├────────────────────────────────────────┤  *
*   │                vcrp.nl                 │  *
*   └────────────────────────────────────────┘  *
*************************************************/
import { Component, OnInit } from '@angular/core';
import { PaintballLobby } from '../../../../../../../library/interfaces/paintball';
import { CommonModule } from '@angular/common';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { ApiService } from '../../../../public/services/api.service';
import { DataService } from '../../../../public/services/data.service';
import { Router, RouterLink } from '@angular/router';
import { SocketUpdateMessage } from '../../../../../../../library/interfaces/socket';

@Component({
  selector: 'app-paintball',
  standalone: true,
  imports: [ CommonModule, RouterLink ],
  templateUrl: './paintball.component.html',
  styleUrl: './paintball.component.scss'
})
export class PaintballComponent implements OnInit {
  public fetchingData = true;
  public lobbies: PaintballLobby[] = [];

  constructor(
    private websocketService: WebsocketService,
    private dataService: DataService,
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
        switch(update.type){
          case 'paintball':
            this.lobbies = update.data.lobbies;
            break;
        }
    });
  }

  async ngOnInit(): Promise<void> {
    this.lobbies = await this.dataService.getPaintballLobbies();
    this.fetchingData = false;
  }
}
