/************************************************
*   :---:.                     .::::..          *
*   :@@@@@%-               :+#@@@@@@@@@%*-      *
*    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
*     -@@@@@@-          #@@@@@@@%***#@@@@=      *
*      =@@@@@@.        #@@@@@%-       :=        *
*       =@@@@@%.      #@@@@@#                   *
*        +@@@@@%.    #@@@@@*                    *
*         +@@@@@@.  %@@@@@*.                    *
*           @@@@@%:%@@@@@*.%%.                  *
*            @@@@@@@@@@@# %@@@+:     .=+.       *
*            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
*            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
*             :@@@@@@@:     :+#@@@@@@@%*-       *
*              ......                           *
*   ┌────────────────────────────────────────┐  *
*   │         Vereenigde Compagnie           │  *
*   │             fiveM server               │  *
*   ├────────────────────────────────────────┤  *
*   │                vcrp.nl                 │  *
*   └────────────────────────────────────────┘  *
*************************************************/
import { Injectable } from '@angular/core';
import { WebsitePlayer } from '../../../../../library/interfaces/player';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public lastFetch: Date = new Date(0);
  private user: WebsitePlayer | null = null;
  private avatar: string = 'assets/img/placeholders/no_avatar.jpg';
  private userName: string = 'Gebruiker';

  constructor( 
      private apiService: ApiService
  ) { }

  public async getUser(){
    const cachedUser = localStorage.getItem('user-cache');
    const cachedTime = localStorage.getItem('user-cache-time') || 0;
    const cacheAge = new Date().getTime() - new Date(cachedTime).getTime();

    if(cachedUser && cacheAge < 15 * 60 * 1000){
      this.user = {
        ...JSON.parse(cachedUser),
        cached: true,
        cacheAge
      };
    } else {
      const userCall = await this.apiService.getUserFromToken();
      if(userCall.success){
        this.user = {
          ...userCall.data,
          cached: false
        };

        localStorage.setItem('user-cache-time', new Date().toISOString());
        localStorage.setItem('user-cache', JSON.stringify(this.user));
      }
    }

    return this.user;
  }

  public getAvatar(): string {
    if(this.avatar === 'assets/img/placeholders/no_avatar.jpg'){
      const localStorageAvatar = localStorage.getItem('avatar');
      if(localStorageAvatar){
        const discAvatarHash = localStorage.getItem('avatar')!.replaceAll('"', '');
        const discordId = localStorage.getItem('did')!.replaceAll('"', '');
        if(discAvatarHash && discordId){
          this.avatar = `https://cdn.discordapp.com/avatars/${discordId}/${discAvatarHash}.png`;
        }
      }
    }

    return this.avatar;
  }

  public getUsername(): string {
    if(this.userName === 'Gebruiker'){
      const localStorageUsername = localStorage.getItem('username')!.replaceAll('"', '');
      if(localStorageUsername) this.userName = localStorageUsername;
    }

    return this.userName;
  }
}
