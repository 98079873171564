<div class="d-flex w-100 flex-column flex-md-row">
  <div class="d-flex news flex-column me-4 flex-grow-1">
    <div class="panel mb-4">
      <h3 class="p-4 pb-0">Nieuws</h3>
      <ng-container *ngIf="!fetching.news">
        <article class="row m-0 emboss" *ngFor="let article of news; let i = index">
          <div class="col-12 ps-0 pe-0 col-md-8 order-2" [class.order-md-1]="i % 2 == 0">
            <div class="d-flex flex-column p-2 p-md-4 h-100" [class.ps-0]="i % 2 !== 0" [class.pe-0]="i % 2 == 0">
              <h4>{{ article.title }}</h4>
              <div class="content d-flex flex-grow-1" [innerHTML]="article.content | htmlMarkdown"></div>
              <div class="footer d-flex align-items-center">
                Geplaatst op {{ article.date | date: 'dd-MM-yyyy' }} <span class="ms-1 d-flex align-items-center" *ngIf="article.author">- <img class="ms-1 me-1" src="{{article.author.avatar}}?size=32" />{{ article.author.name }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 ps-0 pe-0 col-md-4 order-1" [class.order-md-2]="i % 2 == 0">
            <div class="p-2">
              <div class="image ratio ratio-4x3">
                <img src="{{article.image}}" alt="{{article.title}}">
              </div>
            </div>
          </div>
        </article>
      </ng-container>
      <ng-container *ngIf="fetching.news">
        <div class="d-flex p-4 justify-content-center align-items-center flex-column">
          <div class="loader mb-2"></div>
          Laatste nieuwsberichten ophalen...
        </div>
      </ng-container>
    </div>
  </div>
  <div class="d-flex sidebar flex-column mb-4 mb-md-0">
    <div class="panel">
      <h3 class="p-4 pb-0">Statistieken</h3>
      <div class="p-4">
        <div class="d-flex flex-column statistics">
          <div class="d-flex w-100 stat">
            <span class="w-75 fw-bold">Spelers Online</span>
            <span *ngIf="fetching.stats"><div class="loader"></div></span>
            <span *ngIf="!fetching.stats">{{ stats.players.online }}</span>
          </div>
          <div class="d-flex w-100 stat">
            <span class="w-75 fw-bold">Spelers Totaal</span>
            <span *ngIf="fetching.stats"><div class="loader"></div></span>
            <span *ngIf="!fetching.stats">{{ stats.players.total }}</span>
          </div>
          <button class="btn mt-3 btn-fivem w-100 flex-column flex-md-row justify-content-center p-2" (click)="gameConnect()">
            <div class="d-flex">
              <img src="assets/img/fivem_logo.png"/> Speel via <span>FiveM</span>
            </div>
            <div class="d-flex d-md-none notifier">*ALLEEN OP DESKTOP</div>
          </button>
          <hr class="mt-3 mb-3"/>
          <div class="d-flex w-100 stat">
            <span class="w-75 fw-bold">Discord Online</span>
            <span *ngIf="fetching.stats"><div class="loader"></div></span>
            <span *ngIf="!fetching.stats">{{ stats.discord.online }}</span>
          </div>
          <div class="d-flex w-100 stat">
            <span class="w-75 fw-bold">Discord Totaal</span>
            <span *ngIf="fetching.stats"><div class="loader"></div></span>
            <span *ngIf="!fetching.stats">{{ stats.discord.total }}</span>
          </div>
          <a href="https://discord.gg/BXWeQsqyWZ" class="btn mt-3 btn-discord w-100 flex-md-row justify-content-center p-2" (click)="gameConnect()"><i class="fa-brands fa-discord"></i> Join onze <span>Discord</span></a>
        </div>
      </div>
    </div>
  </div>
</div>