<div class="panel shadow d-flex flex-column login w-100" *ngIf="authenticating">
  <h3 class="p-4 pb-0">Bezig met inloggen...</h3>
  <hr />
  <div class="d-flex p-5 justify-content-center align-items-center">
    <div class="loader"></div>
  </div>
</div>
<div class="panel shadow d-flex flex-column login w-100" *ngIf="!authenticating && loginError == ''">
  <h3 class="p-4 pb-0">Login</h3>
  <hr />
  <div class="row">
    <div class="col-12 col-md-8 order-md-1 order-2">
      <div class="p-4">
        Om je account te bevestigen en veilige toegang te bieden tot onze website en aangrenzende systemen, hebben we jouw toestemming nodig om bepaalde gegevens van je Discord-account te gebruiken. Maak je geen zorgen, we zullen deze gegevens alleen gebruiken voor deze specifieke doeleinden.
        <br /> <br />
        Klik op de onderstaande knop om door te gaan naar Discord en ons toestemming te verlenen:<br />
        <small class="text-warning"><i class="font-weight:500">Let op:</i> Om via Discord in te kunnen loggen, moet je eerst minimaal één keer inloggen op de server via FiveM.</small>
        <br /><br />
        <div class="d-flex align-items-center w-100 flex-column flex-md-row">
          <button class="btn btn-discord w-mobile-100" (click)="promptLogin()"><i class="fa-brands fa-discord"></i> Login met <span>Discord</span></button>
          <div class="divider">- of -</div>
          <button class="btn btn-fivem w-mobile-100 flex-column flex-md-row" (click)="gameConnect()">
            <div class="d-flex">
              <img src="assets/img/fivem_logo.png"/> Speel via <span>FiveM</span>
            </div>
            <div class="d-flex d-md-none notifier">*ALLEEN OP DESKTOP</div> 
          </button>
        </div>
        <br /><br />
        Bedankt voor je medewerking en welkom bij onze community!
      </div>
    </div>
    <div class="right-container image-container d-flex col-12 col-md-4 order-md-2 order-1 mb-md-0 mt-md-0 mt-2 mb-2 justify-content-center align-items-center">
      <div class="right-container-content">
        <img src="assets/img/skyview.jpg" />
      </div>
    </div>
  </div>
</div>
<div class="panel shadow d-flex flex-column login w-100" *ngIf="!authenticating && loginError !== ''">
  <h3 class="p-4 pb-0">Login</h3>
  <hr />
  <div class="d-flex row w-100 ms-0">
    <div class="col-12 col-md-8 order-md-1 order-2">
      <div class="p-4">
        Ohnee!, Inloggen is momenteel niet mogelijk. De server gaf de volgende foutmelding:
        <div class="d-flex">
          <div class="alert alert-warning mt-1 mb-3" role="alert">
            [ERR] {{ loginError }}
          </div>
        </div>
        <hr />
        <p class="pt-2">Probeer het later nog eens of neem contact op met iemand van het Staff team op Discord.</p>
      </div>
    </div>
    <div class="right-container d-flex col-12 col-md-4 order-md-2 order-1 mb-md-0 mb-mt-0 mt-2 mb-2 justify-content-center align-items-center">
      <div class="right-container-content p-5">
        <i class="fa-duotone fa-heart-crack"></i>
      </div>
    </div>
  </div>
</div>
