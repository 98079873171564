/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
**************************************************/
import { Component } from '@angular/core';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent {

}
