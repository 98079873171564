/************************************************
*   :---:.                     .::::..          *
*   :@@@@@%-               :+#@@@@@@@@@%*-      *
*    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
*     -@@@@@@-          #@@@@@@@%***#@@@@=      *
*      =@@@@@@.        #@@@@@%-       :=        *
*       =@@@@@%.      #@@@@@#                   *
*        +@@@@@%.    #@@@@@*                    *
*         +@@@@@@.  %@@@@@*.                    *
*           @@@@@%:%@@@@@*.%%.                  *
*            @@@@@@@@@@@# %@@@+:     .=+.       *
*            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
*            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
*             :@@@@@@@:     :+#@@@@@@@%*-       *
*              ......                           *
*   ┌────────────────────────────────────────┐  *
*   │         Vereenigde Compagnie           │  *
*   │             fiveM server               │  *
*   ├────────────────────────────────────────┤  *
*   │                vcrp.nl                 │  *
*   └────────────────────────────────────────┘  *
*************************************************/
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { WebsitePlayer } from '../../../../../../library/interfaces/player';
import { CommonModule } from '@angular/common';
import { DurationTransformPipe } from '../../../pipes/duration-transform.pipe';
import { MapComponent } from '../../components/map/map.component';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [ CommonModule, DurationTransformPipe, MapComponent ],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})
export class AccountComponent implements OnInit {
  public user: WebsitePlayer | null = null;
  public fetching = true;
  public avatar = '';
  public userName = '';
  public selectedCharacter = -1;
  public totalPlaytime = 0;
  public selectionActive = false;
  
  constructor(
    private userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
    this.avatar = this.userService.getAvatar();
    this.user = await this.userService.getUser();
    this.userName = this.userService.getUsername();
    this.selectedCharacter = this.user!.characters.length > 0 ? 0 : -1;
    this.totalPlaytime = this.user!.characters.reduce((acc, char) => acc + char.playTimeInMinutes, 0);
    this.fetching = false;
  }

  public setSelectedCharacter(index: number){
    this.selectedCharacter = index;
    this.selectionActive = true;
  }

  public resetSelectedCharacter(){
    this.selectionActive = false;
  }
}
