/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component } from '@angular/core';
import { SocketUpdateMessage } from '../../../../../../../library/interfaces/socket';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { TimeWeatherStatus } from '../../../../../../../library/interfaces/weather';
import { CommonModule } from '@angular/common';
import { WeekDayPipe } from '../../../../pipes/week-day.pipe';
import { NiceTimePipe } from '../../../../pipes/nice-time.pipe';
import { RouterLink } from '@angular/router';
import { DataService } from '../../../../public/services/data.service';

@Component({
  selector: 'app-weather',
  standalone: true,
  imports: [ CommonModule, WeekDayPipe, NiceTimePipe, RouterLink ],
  templateUrl: './weather.component.html',
  styleUrl: './weather.component.scss'
})
export class WeatherComponent {
  public weatherStatus: TimeWeatherStatus | null = null;

  constructor(
    private websocketService: WebsocketService,
    private dataService: DataService
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
        switch(update.type){
          case 'weather':
              this.weatherStatus = update.data;
            break;
        }
    });
  }

  private async getTimeWeatherStatus(): Promise<void> {
    const weatherCall = await this.dataService.getTimeWeatherStatus();
    if(weatherCall){
      // @ts-ignore
      this.weatherStatus = weatherCall;

      const interval = setInterval(() => {
        if(this.weatherStatus !== null){
          if(this.weatherStatus?.time.minute < 59){
            this.weatherStatus.time.minute = this.weatherStatus?.time.minute + 1;
          } else {
            this.getTimeWeatherStatus();
            clearInterval(interval);
          }
        }
      }, 8 * 1000);
    }
  }

  ngOnInit(): void { 
    this.getTimeWeatherStatus();
  }
} 
