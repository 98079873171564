<div class="card mt-4">
    <h5 class="p-3">Totaal geregistreerde spelers</h5>
    <div class="d-flex p-4 justify-content-center align-items-center" *ngIf="playerList.length == 0">
        <div class="loader"></div>
    </div>
    <table class="table table-striped w-100" *ngIf="playerList.length > 0">
        <thead>
            <tr>
                <th>#</th>
                <th>Naam</th>
                <th>U.ID</th>
                <th>C.ID</th>
                <th>Tot. speeltijd</th>
                <th>Laatste locatie</th>
                <th>Laatste login</th>
                <th>Aanmaak datum</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let playerCharacter of playerList; let i = index">
                <td> {{ i + 1 }} </td>
                <td> {{ playerCharacter.firstName }} {{ playerCharacter.lastName }} <span> <sup>{{ playerCharacter.nationality }}</sup></span> </td>
                <td> {{ playerCharacter.userId }} </td>
                <td> {{ playerCharacter.id }} </td>
                <td> {{ playerCharacter.playTime | durationTransform }} </td>
                <td *ngIf="playerCharacter.location"> {{ playerCharacter.location!.name.streetName }}<span *ngIf="playerCharacter.location.name.crossingRoad">, {{ playerCharacter.location.name.crossingRoad }}</span></td>
                <td *ngIf="!playerCharacter.location">Unknown</td>
                <td> {{ playerCharacter.dateLastLogin | date : 'yyyy-MM-dd' }} </td>
                <td> {{ playerCharacter.dateInsert | date : 'yyyy-MM-dd' }} </td>
                <td>
                    <button class="btn btn-primary w-100 justify-content-center" routerLink="/admin/players/inspect/{{playerCharacter.userId}}"><i class="fa-solid fa-magnifying-glass"></i> Inspect</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>