/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component } from '@angular/core';
import { ActiveVehiclesComponent } from '../../components/widgets/active-vehicles/active-vehicles.component';
import { ActivePlayersComponent } from '../../components/widgets/active-players/active-players.component';
import { CommonModule } from '@angular/common';
import { ServerLogsComponent } from '../../components/widgets/server-logs/server-logs.component';
import { RouterLink } from '@angular/router';
import { LiveMapComponent } from '../../components/widgets/live-map/live-map.component';
import { WeatherComponent } from '../../components/widgets/weather/weather.component';

@Component({
  selector: 'app-overview',
  standalone: true,
  imports: [ ActiveVehiclesComponent,
             ActivePlayersComponent, 
             CommonModule, 
             RouterLink, 
             ServerLogsComponent, 
             LiveMapComponent,
             WeatherComponent 
  ],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss'
})
export class OverviewComponent {
  
}
