/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { mapToCanActivate } from "@angular/router";
import { AccountComponent } from "./public/pages/account/account.component";
import { LoginComponent } from "./public/pages/account/login.component";
import { LogoutComponent } from "./public/pages/account/logout.component";
import { AuctionsComponent } from "./public/pages/auctions/auctions.component";
import { InfoComponent } from "./public/pages/info/info.component";
import { RulesComponent } from "./public/pages/rules/rules.component";
import { AuthGuard } from "./public/services/auth/auth-guard.service";
import { FaqComponent } from "./public/pages/faq/faq.component";
import { AboutComponent } from "./public/pages/about/about.component";
import { PrivacyPolicyComponent } from "./public/pages/privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "./public/pages/terms-and-conditions/terms-and-conditions.component";

export const PublicRoutes = [
  {
    path: 'login',
    component: LoginComponent,
    title: 'VCRP - Inloggen'
  },
  {
    path: 'regelgeving',
    component: RulesComponent,
    title: 'VCRP - Regelgeving'
  },
  {
    path: 'info',
    component: InfoComponent,
    title: 'VCRP - Server info'
  },
  {
    path: 'veilingen',
    component: AuctionsComponent,
    title: 'VCRP - Veilingen'
  },
  {
    path: 'logout',
    component: LogoutComponent,
    title: 'VCRP - Logout'
  },
  { 
    path: 'account', 
    component: AccountComponent, 
    canActivate: mapToCanActivate([AuthGuard]),
    title: 'VCRP - Mijn account'
  },
  {
    path: 'faq',
    component: FaqComponent,
    title: 'VCRP - FAQ'
  },
  {
    path: 'over-ons',
    component: AboutComponent,
    title: 'VCRP - Over ons'
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    title: 'VCRP - Privacy Policy'
  },
  {
    path: 'algemene-voorwaarden',
    component: TermsAndConditionsComponent,
    title: 'VCRP - Algemene Voorwaarden'
  },
]