/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekDay',
  standalone: true
})
export class WeekDayPipe implements PipeTransform {

  transform(dayNumber: number): string {
    switch (dayNumber) {
      case 1:
        return 'Zondag';
      case 2:
        return 'Maandag';
      case 3:
        return 'Dinsdag';
      case 4:
        return 'Woensdag';
      case 5:
        return 'Donderdag';
      case 6:
        return 'Vrijdag';
      case 7:
        return 'Zaterdag';
      default:
        return '';
    }
  }

}
