/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component, ViewChild } from '@angular/core';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { SocketUpdateMessage } from '../../../../../../../library/interfaces/socket';
import { LogMessage } from '../../../../../../../library/interfaces/log';
import { CommonModule } from '@angular/common';
import { ApiService } from '../../../../public/services/api.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { Router } from '@angular/router';

@Component({
  selector: 'app-server-logs',
  standalone: true,
  imports: [ CommonModule, AgGridAngular],
  templateUrl: './server-logs.component.html',
  styleUrl: './server-logs.component.scss'
})
export class ServerLogsComponent {
  @ViewChild('agGrid', { static: false }) agGrid: any;
  
  constructor(
    private websocketService: WebsocketService,
    private apiService: ApiService,
    private router: Router
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
        switch(update.type){
          case 'log':
            const logMessage: LogMessage = update.data;
            this.rowData!.push({
              time: (new Date(parseInt(logMessage.time as string, 10))).toISOString(),
              level: logMessage.logLevel,
              userId: logMessage.userId,
              message: logMessage.message
            });
            if (this.agGrid && this.agGrid.api) this.agGrid.api.setRowData(this.rowData);
            break;
        }
    });
  }

  public agOptions = {
    pagination: true,
    paginationPageSize: 15,
    paginationPageSizeSelector: [5, 10, 15, 25, 50, 100, 200],
  }
  public rowData: any[] | null = [];
  public gridOptions: GridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      sortable: false,
      resizable: false
    },
    columnDefs: [
      { field: 'time', valueFormatter: this.dateValueFormatter, maxWidth: 160, sortable: true, sort: 'desc' },
      { field: 'level',
        maxWidth: 120,
        cellClass: 'd-flex justify-content-center',
        cellRenderer: (params: any) => {
          let cssClass = '';
          let value = params.value;
          
          if (value === 'info' || value === 'default') {
            cssClass = 'badge justify-content-center text-bg-info';
          } else if (value === 'debug' || value === 'trace') {
            cssClass = 'badge justify-content-center text-bg-secondary';
          } else if (value === 'error') {
            cssClass = 'badge justify-content-center text-bg-danger';
          } else if (value === 'warn') {
            cssClass = 'badge justify-content-center text-bg-warning';
          } else if (value === 'success') {
            cssClass = 'badge justify-content-center text-bg-success';
          }

          return `<span class="${cssClass}">${value}</span>`;
        },
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['info', 'warn', 'error', 'debug', 'trace', 'success', 'default'] // Specify filter values
        }
      },
      { 
        field: 'userId',
        headerName: 'UID',
        maxWidth: 70,
        cellClassRules: {
          'ag-data-user-select': (params: any) => params.value > 0,
          'd-none': (params: any) => params.value < 1
        }
      },
      { field: 'message' }
    ]
  }


  async ngOnInit(): Promise<void> {
    const response = await this.apiService.call('/logs/server');
    if(response.success){
      this.rowData = response.data;
    }
  }

  public onCellClicked(event: any) {
    if(event.column.userProvidedColDef.field === 'userId'){
      const fieldValue = event.value;
      const routePath = `/admin/players/inspect/${fieldValue}`;
      this.router.navigateByUrl(routePath);
    }
  }

  private dateValueFormatter(params: any): string {
    const date = new Date(params.value);
    return `${date.toISOString().split('T')[0]} ${date.toISOString().split('T')[1].split('.')[0]}`;
  }
}
