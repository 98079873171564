<!-- <header class="d-flex w-100">
  <div class="navbar-wrapper w-100">
    <div class="col-4">
      <div class="social-top">
        <a rel="nofollow" target="_blank" href="#"><i class="fab fa-youtube"></i> </a> <a rel="nofollow" target="_blank"
          href="#"><i class="fab fa-steam-symbol"></i></a> <a rel="nofollow" target="_blank" href="#"><i
            class="fab fa-twitch"></i></a> <a rel="nofollow" target="_blank" href="#"><i class="fab fa-discord"></i></a>
      </div>
    </div>
    <div class="logo col-4">
      <img src="assets/img/logo.svg" alt="logo" />
    </div>
    
  </div>
</header>
<nav>
  <app-navigation></app-navigation>
</nav> -->


<div class="header">
  <div class="top-bar d-none d-sm-flex ">
    <div class="container text-center">
      Vereenigde Compagnie - Roleplay Community
    </div>
  </div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-6 col-lg-4 d-block d-xl-none py-3 py-xl-0">
          <div class="header-logo small">
            <a routerLink="/"><img src="assets/img/logo.svg" alt="Vereenigde Compagnie"></a>
          </div>
      </div>
      <div class="col-6 col-lg-8 col-xl-5 text-end text-xl-start">
        <nav>
          <app-navigation></app-navigation>
        </nav>
      </div>
      <div class="col-md-4 col-lg-2 text-center d-none d-xl-block">
          <div class="header-logo">
              <a routerLink="/"><img src="assets/img/logo.svg" alt="Vereenigde Compagnie"></a>
          </div>
      </div>
      <div class="col-md-4 col-lg-5 d-none d-xl-block">
          <div class="header-right d-flex align-items-center justify-content-end">
            <div class="d-flex login pe-4 justify-content-end align-items-center" *ngIf="!isAuthenticated">
              <button class="btn btn-fivem w-mobile-100 flex-column flex-md-row" (click)="gameConnect()">
                <div class="d-flex">
                  <img src="assets/img/fivem_logo.png"/> Speel via <span>FiveM</span>
                </div>
                <div class="d-flex d-md-none notifier">*ALLEEN OP DESKTOP</div> 
              </button>
              <div class="divider">- of -</div>
              <button class="btn btn-discord" routerLink="/login"><i class="fa-brands fa-discord"></i> Login met <span>Discord</span></button>
            </div>
            <div class="d-flex account pe-4 justify-content-end align-items-center" *ngIf="isAuthenticated">
              <div class="options me-1">
                <div class="link"><a *ngIf="isStaff()" href="/admin"><i class="fa-fw fa-solid fa-shield-quartered"></i> admin</a></div>
                <div class="link" routerLink="/account" [routerLinkActive]="'active'"><a><i class="fa-fw fa-solid fa-user"></i> account</a></div>
                <div class="link"><a (click)="logout()"><i class="fa-fw fa-regular fa-arrow-right-from-bracket"></i> uitloggen</a></div>
                <!-- <a><i class="fa-regular fa-bell"></i></a> -->
              </div>
              <div class="avatar" routerLink="/account">
                <img src="{{ avatar }}"/>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>