import { Injectable } from '@angular/core';
import { environment } from '../../../../../library/environment/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor() { }

  public async getUserFromToken(): Promise<any> {
    return await this.call('/me');
  }

  public async call(url: string, data?: any): Promise<any> {
    let headers: any = { 'Content-Type': 'application/json' };
    const token = localStorage.getItem('token');
    if(token){
      headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
    }
    let fetchData: any = {
      headers
    };
    if(typeof data !== 'undefined'){
      fetchData = {
        headers,
        method: 'POST',
        body: JSON.stringify(data)
      }
    }

    const apiUrl = environment.apiUrl;
    try {
      const request = await fetch(`${apiUrl}${url}`, fetchData);
      const response = await request.json();
      return response;
    } catch (error) {
      setTimeout(async () => {
        return await this.call(url, data);
      }, 2500);
    }
  }

  public gameConnect(): void {
    const url: string = `fivem://connect/${environment.gameUrl}`;
    const anchor: HTMLAnchorElement = document.createElement('a');

    anchor.href = url;
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click(); 
    document.body.removeChild(anchor);

    setTimeout(() => {
        if (document.hasFocus()) {
            console.log('Unable to open the custom URL scheme');
        }
    }, 1000);
  }
}
