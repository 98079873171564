/************************************************
*   :---:.                     .::::..          *
*   :@@@@@%-               :+#@@@@@@@@@%*-      *
*    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
*     -@@@@@@-          #@@@@@@@%***#@@@@=      *
*      =@@@@@@.        #@@@@@%-       :=        *
*       =@@@@@%.      #@@@@@#                   *
*        +@@@@@%.    #@@@@@*                    *
*         +@@@@@@.  %@@@@@*.                    *
*           @@@@@%:%@@@@@*.%%.                  *
*            @@@@@@@@@@@# %@@@+:     .=+.       *
*            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
*            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
*             :@@@@@@@:     :+#@@@@@@@%*-       *
*              ......                           *
*   ┌────────────────────────────────────────┐  *
*   │         Vereenigde Compagnie           │  *
*   │             fiveM server               │  *
*   ├────────────────────────────────────────┤  *
*   │                vcrp.nl                 │  *
*   └────────────────────────────────────────┘  *
*************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../public/services/api.service';
import { PaintballLobby } from '../../../../../../../library/interfaces/paintball';
import { CommonModule } from '@angular/common';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { SocketUpdateMessage } from '../../../../../../../library/interfaces/socket';
import { DayNightPipe } from '../../../../pipes/daynight.pipe';
import { WeatherTranslatePipe } from '../../../../pipes/weather-translate.pipe';

@Component({
  selector: 'app-inspect',
  standalone: true,
  imports: [ CommonModule, DayNightPipe, WeatherTranslatePipe ],
  templateUrl: './inspect.component.html',
  styleUrl: './inspect.component.scss'
})
export class InspectComponent implements OnInit {
  public lobbyId: string = '';
  public lobby: PaintballLobby | null = null;
  public scores: any = {};
  public loading = true;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private websocketService: WebsocketService
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
        switch(update.type){
          case 'paintball':
            const currentLobby = update.data.lobbies.find((lobby: PaintballLobby) => lobby.uuid === this.lobbyId);
            if(currentLobby){
              this.lobby = this.sortLobby(currentLobby);
            }
            break;
        }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.lobbyId = params['uuid'];
      await this.getLobbyDetails(this.lobbyId);
    });
  }

  private async getLobbyDetails(lobbyId: string): Promise<void> {
    const lobbyCall = await this.apiService.call('/paintball/get/by-id/' + lobbyId);
    if(lobbyCall.success){
      this.lobby = this.sortLobby(lobbyCall.data);
    }

    setTimeout(() => {
      this.getLobbyDetails(lobbyId);
    }, 60 * 1000);

    this.loading = false;
  }

  private sortLobby(lobby: PaintballLobby){
    lobby.players = lobby.players!.sort((a, b) => {
      return b.score - a.score;
    });

    return lobby;
  }
}
