<!-- Default Menu -->
<div [class.show]="menuOpen" class="fade" (click)="toggleMenu()" *ngIf="type === 'default'"></div>
<div [class.open]="menuOpen" class="d-flex menu-button d-sm-none w-100 justify-content-end" (click)="toggleMenu()" *ngIf="type === 'default'">
  <div class="box">
    <div [class.not-active]="!menuOpen" [class.active]="menuOpen" class="hamburger d-flex flex-column">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>  
</div>
<div [class.show]="menuOpen" class="navigation mobile d-sm-flex" *ngIf="type === 'default'">
  <div class="logo d-flex flex-column align-items-center d-sm-none w-100">
    <div class="logo-container w-100">
      <img src="assets/img/logo.svg" alt="Vereenigde Compagnie">
    </div>
    VEREENIGDE COMPAGNIE
  </div>
  <div (click)="closeMenu()" class="link" routerLink="/" [routerLinkActiveOptions]="{exact:true}" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-house"></i> Home</a></div>
  <div (click)="closeMenu()" class="link" routerLink="/regelgeving" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-scale-unbalanced-flip"></i>
    <span class="d-none d-md-flex d-xxl-none">Regels</span>
    <span class="d-flex d-md-none d-xxl-flex">Regelgeving</span>
  </a></div>
  <div (click)="closeMenu()" class="link" routerLink="/info" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-circle-info"></i> Server info</a></div>
  <div (click)="closeMenu()" class="link" routerLink="/veilingen" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-coins"></i> Veilingen</a></div>
  <div (click)="closeMenu()" class="link d-flex d-sm-none" routerLink="/account" [routerLinkActive]="'active'"><a><i class="fa-fw fa-brands fa-discord"></i> Account</a></div>
</div>

<!-- Account Menu -->
<div class="navigation show d-flex" *ngIf="type === 'account'">
  <div class="link" routerLink="/account" [routerLinkActiveOptions]="{exact:true}" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-grid-2"></i> Overzicht</a></div>
  <div class="link" routerLink="/account" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-users"></i> Karakters</a></div>
  <div class="link" routerLink="/account" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-headset"></i> Mijn tickets</a></div>
  <div class="link" routerLink="/account" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-sliders"></i> Instellingen</a></div>
</div>

<!-- About Menu -->
<div class="navigation show d-flex" *ngIf="type === 'about'">
  <div class="link" routerLink="/over-ons" [routerLinkActiveOptions]="{exact:true}" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-address-card"></i> Over ons</a></div>
  <div class="link" routerLink="/faq" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-square-question"></i> FAQ</a></div>
  <div class="link" routerLink="/algemene-voorwaarden" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-scale-balanced"></i> Algemene voorwaarden</a></div>
  <div class="link" routerLink="/privacy-policy" [routerLinkActive]="'active'"><a><i class="fa-fw fa-thin fa-fingerprint"></i> Privacy Policy</a></div>
</div>