<div class="admin d-flex h-100">
    <div class="d-flex navigation">
        <app-navigation class="d-flex flex-column h-100"></app-navigation>
    </div>
    <div class="d-flex flex-column w-100">
        <div class="d-flex topbar w-100">
            <app-topbar></app-topbar>
        </div>
        <div class="d-flex content w-100 h-100">
            <div class="d-flex w-100 pb-5">
                <router-outlet />
            </div>
        </div>
    </div>
</div>