<div class="d-flex">
    <div class="d-flex flex-column w-100 h-100 me-3">
        <div class="card">
            <div class="d-flex text-center justify-content-center p-5 w-100" *ngIf="resourceList.length === 0">
                <span *ngIf="!fetchingData">No resources found.</span>
                <span *ngIf="fetchingData"><div class="loader"></div></span>
            </div>
            <table class="table table-striped w-100" *ngIf="resourceList.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Naam</th>
                        <th>Status</th>
                        <th class="text-end"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let resource of resourceList; let i = index">
                        <td> {{ i + 1 }} </td>
                        <td> {{ resource.name }} </td>
                        <td>
                            <span class="badge text-bg-success" *ngIf="resource.state === 'started'">{{ resource.state }}</span>
                            <span class="badge text-bg-info" *ngIf="resource.state === 'starting'">{{ resource.state }}</span>
                            <span class="badge text-bg-warning" *ngIf="resource.state === 'stopping'">{{ resource.state }}</span>
                            <span class="badge text-bg-danger" *ngIf="resource.state === 'stopped' || resource.state === 'missing' || resource.state === 'uninitialized' || resource.state === 'unknown'">{{ resource.state }}</span>
                        </td>
                        <td>
                            <div class="d-flex justify-content-end actions">
                                <div class="loader" *ngIf="resourceDelayingUpdate.includes(resource.name)"></div>
                                <div class="d-flex quick-buttons" *ngIf="!resourceDelayingUpdate.includes(resource.name)">
                                    <button (click)="updateResource(resource, 'start')"
                                        [class.disabled]="resource.state === 'started'
                                                        || resource.state === 'stopping'
                                                        || resource.state === 'uninitialized'
                                                        || resource.state === 'unknown'
                                                        || resource.state === 'missing'
                                                        || resource.state === 'starting'">
                                        <i class="fa-solid fa-play"></i>
                                    </button>
                                    <button (click)="updateResource(resource, 'stop')" 
                                        [class.disabled]="resource.state === 'stopped' 
                                                        || resource.state === 'stopping'
                                                        || resource.state === 'uninitialized'
                                                        || resource.state === 'unknown'
                                                        || resource.state === 'missing'
                                                        || resource.state === 'starting'">
                                        <i class="fa-solid fa-stop"></i>
                                    </button>
                                    <button (click)="updateResource(resource, 'restart')"
                                        [class.disabled]="resource.state === 'stopped' 
                                                        || resource.state === 'stopping'
                                                        || resource.state === 'uninitialized'
                                                        || resource.state === 'unknown'
                                                        || resource.state === 'missing'
                                                        || resource.state === 'starting'
                                                        ">
                                        <i class="fa-solid fa-repeat"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>