<div class="footer">
  <div class="top-bar">
    <div class="container">
      <div class="row">
        <div class="d-flex flex-column col-12 col-md-3 branding footer-block">
          <div class="d-flex logo">
            <img _ngcontent-ng-c2023011177="" src="assets/img/logo.svg" alt="Vereenigde Compagnie">
          </div>
          <div class="d-flex title justify-content-center">
            Vereenigde Compagnie
          </div>
          <hr />
          <p>
            Een unieke roleplay ervaring voor Nederland en België met een volledig custom framework. Sluit je aan via onze Discord-server en geniet van een dynamische roleplay ervaring.
          </p>
        </div>
        <div class="d-none d-md-flex col-12 flex-column col-md-3 footer-block">
          <h1>Hoofdmenu</h1>
          <app-navigation class="footer-menu"></app-navigation>
        </div>
        <div class="d-flex col-12 flex-column col-md-3 footer-block">
          <h1>Account</h1>
          <app-navigation class="footer-menu" [type]="'account'"></app-navigation>
        </div>
        <div class="d-flex col-12 flex-column col-md-3 footer-block">
          <h1>Overig</h1>
          <app-navigation class="footer-menu" [type]="'about'"></app-navigation>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-bar">
    <div class="container text-center">2024 - Vereenigde Compagnie - Roleplay Community</div>
  </div>
</div>