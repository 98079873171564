/************************************************
*   :---:.                     .::::..          *
*   :@@@@@%-               :+#@@@@@@@@@%*-      *
*    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
*     -@@@@@@-          #@@@@@@@%***#@@@@=      *
*      =@@@@@@.        #@@@@@%-       :=        *
*       =@@@@@%.      #@@@@@#                   *
*        +@@@@@%.    #@@@@@*                    *
*         +@@@@@@.  %@@@@@*.                    *
*           @@@@@%:%@@@@@*.%%.                  *
*            @@@@@@@@@@@# %@@@+:     .=+.       *
*            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
*            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
*             :@@@@@@@:     :+#@@@@@@@%*-       *
*              ......                           *
*   ┌────────────────────────────────────────┐  *
*   │         Vereenigde Compagnie           │  *
*   │             fiveM server               │  *
*   ├────────────────────────────────────────┤  *
*   │                vcrp.nl                 │  *
*   └────────────────────────────────────────┘  *
*************************************************/
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';

/************************************************
* Auth Guard
* Checks if a user is logged in at all
*************************************************/
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(public auth: AuthenticationService, public router: Router) {}
  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      redirectToLogin();
      return false;
    }
    return true;
  }
}

/************************************************
* Admin Guard
* Checks if logged in user is Admin
*************************************************/
@Injectable({
  providedIn: 'root'
})
export class AdminGuard {
  constructor(public auth: AuthenticationService, public router: Router) {}
  canActivate(): boolean {
    if (!this.auth.isAdmin()) {
      redirectToLogin();
      return false;
    }
    return true;
  }
}

/************************************************
* Staff Guard
* Checks if logged in user is Staff
*************************************************/
@Injectable({
  providedIn: 'root'
})
export class StaffGuard {
  constructor(public auth: AuthenticationService, public router: Router) {}
  canActivate(): boolean {
    if (!this.auth.isStaff()) {
      redirectToLogin();
      return false;
    }
    return true;
  }
}


/************************************************
* Redirect function
*************************************************/
function redirectToLogin() {
  const adminPage = window.location.href.includes('/admin');
  const router = new Router();
  if(adminPage){
    window.location.href = '/login';
  } else {
    router.navigate(['/login']);
  }
}