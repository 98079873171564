<div class="d-flex">
    <div class="d-flex flex-column h-100 me-3" [class.w-65]="vehicleList.length > 0" [class.w-100]="vehicleList.length === 0">
        <div class="card">
            <div class="d-flex text-center justify-content-center p-5 w-100" *ngIf="vehicleList.length === 0">
                <span *ngIf="!fetchingData">Er zijn geen voertuigen actief.</span>
                <span *ngIf="fetchingData"><div class="loader"></div></span>
            </div>
            <table class="table table-striped w-100" *ngIf="vehicleList.length > 0">
                <thead>
                    <tr>
                        <th>Kenteken</th>
                        <th>Naam</th>
                        <th class="text-center">Privé voertuige?</th>
                        <th class="text-center">Admin voertuig?</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let vehicle of vehicleList">
                        <td>
                            {{ vehicle.world.license }}
                        </td>
                        <td>
                            {{ vehicle.vendor.manufacturer }} - {{ vehicle.vendor.name }}
                        </td>
                        <td class="text-center">
                            <i class="fa-light fa-octagon-xmark text-danger-emphasis" *ngIf="!vehicle.world.owner!.privatelyOwned"></i>
                            <i class="fa-light fa-circle-check text-success-emphasis" *ngIf="vehicle.world.owner!.privatelyOwned"></i>
                        </td>
                        <td class="text-center">
                            <i class="fa-light fa-octagon-xmark text-danger-emphasis" *ngIf="!vehicle.world.owner!.adminVehicle"></i>
                            <i class="fa-light fa-circle-check text-success-emphasis" *ngIf="vehicle.world.owner!.adminVehicle"></i>
                        </td>
                        <td>
                            <div class="d-flex">
                                <button class="btn btn-primary w-50 justify-content-center" routerLink="/admin/vehicles/active/{{vehicle.world.uuid}}"><i class="fa-solid fa-magnifying-glass"></i></button>
                                <button class="btn btn-danger w-50 justify-content-center" (click)="removeVehicle(vehicle.world.uuid)">
                                    <i class="fa-solid fa-trash" *ngIf="!pendingDeletes.includes(vehicle.world.uuid)"></i>
                                    <div class="loader" *ngIf="pendingDeletes.includes(vehicle.world.uuid)"></div>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="d-flex justify-content-end flex-column w-35 h-100" *ngIf="vehicleList.length > 0">
        <div class="widgets d-flex w-100">
            <div class="card map-container mb-2">
                <div class="map-frame">
                    <app-map [zoomEnabled]="true" [panToLocation]="true" [blipList]="blipList" (clickedMarker)="handleClickedMarker($event)"></app-map>
                </div>
            </div>
        </div>
    </div>
</div>