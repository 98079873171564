/************************************************
*   :---:.                     .::::..          *
*   :@@@@@%-               :+#@@@@@@@@@%*-      *
*    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
*     -@@@@@@-          #@@@@@@@%***#@@@@=      *
*      =@@@@@@.        #@@@@@%-       :=        *
*       =@@@@@%.      #@@@@@#                   *
*        +@@@@@%.    #@@@@@*                    *
*         +@@@@@@.  %@@@@@*.                    *
*           @@@@@%:%@@@@@*.%%.                  *
*            @@@@@@@@@@@# %@@@+:     .=+.       *
*            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
*            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
*             :@@@@@@@:     :+#@@@@@@@%*-       *
*              ......                           *
*   ┌────────────────────────────────────────┐  *
*   │         Vereenigde Compagnie           │  *
*   │             fiveM server               │  *
*   ├────────────────────────────────────────┤  *
*   │                vcrp.nl                 │  *
*   └────────────────────────────────────────┘  *
*************************************************/
import { environment as originalEnvironment } from './environment.production';
import { Environment } from "../interfaces/environment";

export const environment: Environment = {
  ...originalEnvironment,
    gameUrl: `dev.vcrp.nl:30120`,
    production: false,
    apiUrl: `https://api.dev.vcrp.nl`,
    discord:{
        ...originalEnvironment.discord,
        oauth: 'https://discord.com/oauth2/authorize?client_id=927368679916711967&response_type=code&redirect_uri=https%3A%2F%2Fdev.vcrp.nl%2Flogin&scope=identify+guilds+email',
    }
}; 