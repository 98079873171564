<div class="d-flex">
    <div class="d-flex flex-column w-100 h-100 me-3">
        <div class="card">
            <h1>Instances</h1>
            <div class="d-flex text-center justify-content-center p-5 w-100" *ngIf="instanceList.length === 0">
                <span *ngIf="!fetchingData">Er zijn geen instances actief.</span>
                <span *ngIf="fetchingData"><div class="loader"></div></span>
            </div>
            <ag-grid-angular 
            #agGrid
            *ngIf="rowData!.length > 0"
            style="width: 100%; height: 100%;"
            class="ag-theme-quartz ag-table"
            [domLayout]="'autoHeight'"
            [rowData]="rowData"
            [gridOptions]="gridOptions"
            [pagination]="agOptions.pagination"
            [paginationPageSize]="agOptions.paginationPageSize"
            [paginationPageSizeSelector]="agOptions.paginationPageSizeSelector"
            >
            </ag-grid-angular>
        </div>
    </div>
</div>