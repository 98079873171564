/************************************************
*   :---:.                     .::::..          *
*   :@@@@@%-               :+#@@@@@@@@@%*-      *
*    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
*     -@@@@@@-          #@@@@@@@%***#@@@@=      *
*      =@@@@@@.        #@@@@@%-       :=        *
*       =@@@@@%.      #@@@@@#                   *
*        +@@@@@%.    #@@@@@*                    *
*         +@@@@@@.  %@@@@@*.                    *
*           @@@@@%:%@@@@@*.%%.                  *
*            @@@@@@@@@@@# %@@@+:     .=+.       *
*            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
*            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
*             :@@@@@@@:     :+#@@@@@@@%*-       *
*              ......                           *
*   ┌────────────────────────────────────────┐  *
*   │         Vereenigde Compagnie           │  *
*   │             fiveM server               │  *
*   ├────────────────────────────────────────┤  *
*   │                vcrp.nl                 │  *
*   └────────────────────────────────────────┘  *
*************************************************/
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../../library/environment/environment';
import { Socket, io } from 'socket.io-client';
import { AuthenticationService } from './auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
    // @ts-ignore
    private socket: Socket;
    public isConnected$: any;
    public adminPage = window.location.href.includes('/admin');

    constructor(
        private authenticationService: AuthenticationService
    ) {
        const token = localStorage.getItem('token');
        if(this.adminPage){
            if(token){
                this.socket = io(environment.apiUrl, {auth: {token}});
                this.isConnected$ = new BehaviorSubject<boolean>(false);

                this.socket.on('connect', () => {
                    this.isConnected$.next(true);
                });
            
                this.socket.on('disconnect', () => {
                    this.isConnected$.next(false);
                });
            } else {
                this.authenticationService.logout();
            }
        }
    }

    getMessage(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.on('update', (data: any) => {
                observer.next(data);
            });
        });
    }
}
