/************************************************
*   :---:.                     .::::..          *
*   :@@@@@%-               :+#@@@@@@@@@%*-      *
*    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
*     -@@@@@@-          #@@@@@@@%***#@@@@=      *
*      =@@@@@@.        #@@@@@%-       :=        *
*       =@@@@@%.      #@@@@@#                   *
*        +@@@@@%.    #@@@@@*                    *
*         +@@@@@@.  %@@@@@*.                    *
*           @@@@@%:%@@@@@*.%%.                  *
*            @@@@@@@@@@@# %@@@+:     .=+.       *
*            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
*            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
*             :@@@@@@@:     :+#@@@@@@@%*-       *
*              ......                           *
*   ┌────────────────────────────────────────┐  *
*   │         Vereenigde Compagnie           │  *
*   │             fiveM server               │  *
*   ├────────────────────────────────────────┤  *
*   │                vcrp.nl                 │  *
*   └────────────────────────────────────────┘  *
*************************************************/
import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ApiService } from '../api.service';
import { User } from '../../../../../../library/interfaces/user';
import { environment } from '../../../../../../library/environment/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public isAuthenticated$ = new BehaviorSubject<boolean>(this.isAuthenticated());

  constructor(
    private apiService: ApiService,
  ) { }

  public isAuthenticated(): boolean {
    const jwtHelper = new JwtHelperService();
    const token = localStorage.getItem('token');
    const checkExpiration = !jwtHelper.isTokenExpired(token);
    return checkExpiration;
  }

  public isStaff(): boolean {
    const userRoles = JSON.parse(localStorage.getItem('roles') as string);
    return this.isAuthenticated() && userRoles.includes('staff');
  }

  public isAdmin(): boolean {
    const userRoles = JSON.parse(localStorage.getItem('roles') as string);
    return this.isAuthenticated() && userRoles.includes('admin');
  }

  public async login(oAuthToken: string){
    return await this.apiService.call('/authentication/login', {token: oAuthToken}).then((response) => {
      if(response.hasOwnProperty('reason') && response.reason == 'code_expired'){
        window.location.href = environment.discord.oauth;
      } else {
        if(response.success){
          const responseUser: User = response.data
          localStorage.setItem('token', (responseUser.token.vcrp as string));
          localStorage.setItem('roles', JSON.stringify(responseUser.roles));
          // @ts-ignore
          localStorage.setItem('did', JSON.stringify(responseUser.discord_id));
          localStorage.setItem('avatar', JSON.stringify(responseUser.avatar));
          localStorage.setItem('username', JSON.stringify(responseUser.username));
          this.isAuthenticated$.next(true);
          return { success: true };
        } else {
          return response;
        }
      }
    });
  };

  public async logout(){
    localStorage.setItem('token', '');
    await null;
    this.isAuthenticated$.next(false);
    return { success: true }
  }
}
