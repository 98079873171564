/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { OverviewComponent } from './admin/pages/overview/overview.component';
import { VehiclesComponent } from './admin/pages/vehicles/active/vehicles.component';
import { VehiclesRegisteredComponent } from './admin/pages/vehicles/registered/vehicles-registered.component';
import { ActiveComponent } from './admin/pages/players/active/active.component';
import { RegisteredComponent } from './admin/pages/players/registered/registered.component';
import { InspectComponent as PlayerInspectComponent } from './admin/pages/players/inspect/inspect.component';
import { ResourcesComponent } from './admin/pages/resources/resources.component';
import { ModelsComponent } from './admin/pages/vehicles/models/models.component';
import { InstancesComponent } from './admin/pages/instances/instances.component';
import { WipComponent } from './admin/pages/wip/wip.component';
import { PaintballComponent } from './admin/pages/paintball/active/paintball.component';
import { InspectComponent as PaintballInspectComponent } from './admin/pages/paintball/inspect/inspect.component';

export const AdminRoutes = [
  {
    path: '',
    component: OverviewComponent
  },
  {
    path: 'wip',
    component: WipComponent
  },
  {
    path: 'vehicles',
    title: 'VCRP Admin - Voertuigen',
    children: [
      {
        path: 'active',
        title: 'VCRP Admin - Actieve voertuigen',
        component: VehiclesComponent,
      },
      {
        path: 'active/:uuid',
        title: 'VCRP Admin - Voertuig inspecteren',
        component: VehiclesComponent,
      },
      {
        path: 'registered',
        title: 'VCRP Admin - Geparkeerde voertuigen',
        component: VehiclesRegisteredComponent
      },
      {
        path: 'models',
        title: 'VCRP Admin - Voertuig modellen',
        component: ModelsComponent
      }
    ]
  },
  {
    path: 'players',
    title: 'VCRP Admin - Spelers',
    children: [
      {
        path: 'active',
        title: 'VCRP Admin - Actieve Spelers',
        component: ActiveComponent
      },
      {
        path: 'registered',
        title: 'VCRP Admin - Alle spelers',
        component: RegisteredComponent
      },
      {
        path: 'inspect/:userId',
        title: 'VCRP Admin - Speler inspecteren',
        component: PlayerInspectComponent
      }
    ]
  },
  {
    path: 'resources',
    title: 'VCRP Admin - Managers & Modules',
    component: ResourcesComponent
  },
  {
    path: 'paintball',
    title: 'VCRP Admin - Paintball',
    children: [
      {
        path: 'active',
        title: 'VCRP Admin - Paintball overzicht',
        component: PaintballComponent
      },
      {
        path: 'inspect/:uuid',
        title: 'VCRP Admin - Paintball wedstrijd',
        component: PaintballInspectComponent
      }
    ]
  },
  {
    path: 'instances',
    title: 'VCRP Admin - Instances',
    component: InstancesComponent
  }
];