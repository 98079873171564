import { Component } from '@angular/core';

@Component({
  selector: 'app-wip',
  standalone: true,
  imports: [],
  templateUrl: './wip.component.html',
  styleUrl: './wip.component.scss'
})
export class WipComponent {

}
