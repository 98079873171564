<div class="card mt-4">
    <h5 class="p-3">Alle geparkeerde voertuigen</h5>
    <div class="d-flex p-4 justify-content-center align-items-center" *ngIf="vehicleList.length == 0">
        <div class="loader"></div>
    </div>
    <table class="table table-striped w-100" *ngIf="vehicleList.length > 0">
        <thead>
            <tr>
                <th>#</th>
                <th>Kenteken</th>
                <th>Naam</th>
                <th class="text-center">Mods</th>
                <th class="text-center"><i class="fa-solid fa-gas-pump"></i> Brandstof</th>
                <th class="text-center"><i class="fa-solid fa-oil-can"></i> Olie</th>
                <th class="text-center"><i class="fa-solid fa-heart"></i> Body</th>
                <th class="text-center"><i class="fa-solid fa-heart"></i> Engine</th>
                <th class="text-center"><i class="fa-solid fa-heart"></i> Petrol</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let vehicleObject of vehicleList; let i = index">
                <td>
                    {{ i + 1 }}
                </td>
                <td>
                    {{ vehicleObject.world.license }}
                </td>
                <td>
                    {{ vehicleObject.vendor.manufacturer }} - {{ vehicleObject.vendor.name }}
                </td>
                <td class="text-center"> {{ vehicleObject.mods!.mods.length }} </td>
                <td class="text-center"> {{ vehicleObject.metrics.fuel | decimalNumber: 2 }}% </td>
                <td class="text-center"> {{ vehicleObject.metrics.oil | decimalNumber: 2 }}% </td>
                <td class="text-center"> <span *ngIf="vehicleObject.metrics.health && vehicleObject.metrics.health!.body">{{ vehicleObject.metrics.health!.body | decimalNumber: 2 }} </span></td>
                <td class="text-center"> <span *ngIf="vehicleObject.metrics.health && vehicleObject.metrics.health!.engine">{{ vehicleObject.metrics.health!.engine | decimalNumber: 2 }} </span></td>
                <td class="text-center"> <span *ngIf="vehicleObject.metrics.health && vehicleObject.metrics.health!.petrol">{{ vehicleObject.metrics.health!.petrol | decimalNumber: 2 }} </span></td>
                <td>
                    <button class="btn btn-primary"><i class="fa-solid fa-magnifying-glass"></i> Inspecteren</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>