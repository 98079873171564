/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { ApiService } from '../../../../public/services/api.service';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { VehicleVendorIconComponent } from '../../../components/ag-grid/vehicle-vendor-icon/vehicle-vendor-icon.component';

@Component({
  selector: 'app-models',
  standalone: true,
  imports: [ CommonModule, AgGridAngular],
  templateUrl: './models.component.html',
  styleUrl: './models.component.scss'
})
export class ModelsComponent implements OnInit {
  @ViewChild('agGrid', { static: false }) agGrid: any;

  public agOptions = {
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [5, 10, 15, 25, 50, 100, 200],
  }
  public rowData: any[] | null = [];
  public gridOptions: GridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      sortable: false,
      resizable: false
    },
    columnDefs: [
      { field: 'buildYear', headerName: 'Bouwjaar', sortable: true, maxWidth: 100},
      { field: 'type', headerName: 'Type', sortable: true, maxWidth: 150},
      { field: 'manufacturer', headerName: 'Fabrikant', sortable: true, sort: 'asc', cellRenderer: VehicleVendorIconComponent},
      { field: 'name', headerName: 'Naam', sortable: true},
      { field: 'model', headerName: 'Game Model Naam'},
      { field: 'category', headerName: 'Category', sortable: true},
      { field: 'price', headerName: 'Prijs', sortable: true, maxWidth: 150, cellClass: 'price'},
    ]
  }

  constructor(
    private websocketService: WebsocketService,
    private apiService: ApiService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    const response = await this.apiService.call('/vehicles/get/models');
    if(response.success){
      this.rowData = response.data;
    }
  }
}
