/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component, OnInit } from '@angular/core';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { SocketUpdateMessage } from '../../../../../../../library/interfaces/socket';
import { Vehicle } from '../../../../../../../library/interfaces/vehicle';
import { ApiService } from '../../../../public/services/api.service';
import { DataService } from '../../../../public/services/data.service';

@Component({
  selector: 'app-active-vehicles',
  standalone: true,
  imports: [],
  templateUrl: './active-vehicles.component.html',
  styleUrl: './active-vehicles.component.scss'
})
export class ActiveVehiclesComponent implements OnInit {
  public vehicleList: Vehicle[] = [];

  constructor(
    private websocketService: WebsocketService,
    private dataServer: DataService
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
        switch(update.type){
          case 'vehicles':
              this.vehicleList = update.data;
            break;
        }
    });
  }

  async ngOnInit(): Promise<void> {
    this.vehicleList = await this.dataServer.getActiveVehicles();;
  }
}
