<div class="d-flex widgets w-75 pe-3">
    <div class="d-flex flex-column w-100">
        <div class="d-flex mb-4">
            <div class="widget players-widget"  routerLink="/admin/players/active">
                <div class="widget-wrapper">
                    <img src="assets/img/placeholders/players.jpg" />
                    <app-active-players></app-active-players>
                </div>
            </div>
            <div class="widget vehicle-widget" routerLink="/admin/vehicles/active">
                <div class="widget-wrapper">
                    <img src="assets/img/placeholders/vehicles.jpg" />
                    <app-active-vehicles></app-active-vehicles>
                </div>
            </div>
            <div class="widget other-widget">
                <div class="widget-wrapper">
                    <img src="assets/img/placeholders/bahamas.jpg" />
                    <div class="card border-left-info h-100 py-2 me-3">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto icon-bearer">
                                    <i class="fa-solid fa-suitcase"></i>
                                </div>
                                <div class="col mr-2">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col-auto ps-0">
                                            <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800"> <small>0</small></div>
                                        </div>
                                    </div>
                                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Actieve jobs</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-live-map></app-live-map>
        <app-server-logs></app-server-logs>
    </div>
</div>
<div class="d-flex widgets sidebar w-25 h-100">
    <div class="d-flex flex-column w-100">
        <app-weather></app-weather>
        <div class="card mb-3 border-left-info w-100">
            <h1>Rechtermenu</h1>
            <div class="card-body">
                Hier komen ook dingentjens
            </div>
        </div>
        <div class="card mb-3 border-left-info w-100">
            <h1>Rechtermenu</h1>
            <div class="card-body">
                Hier komen ook dingentjens
            </div>
        </div>
        <div class="card mb-3 border-left-info w-100">
            <h1>Rechtermenu</h1>
            <div class="card-body">
                Hier komen ook dingentjens
            </div>
        </div>
    </div>
</div>

