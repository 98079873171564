<div class="d-flex w-100">
    <div class="card w-100">
        <h1>Server logs</h1>
        <div class="d-flex justify-content-center w-100 pb-4" *ngIf="rowData!.length == 0">
            <div class="loader"></div>
        </div>
        <ag-grid-angular 
            #agGrid
            *ngIf="rowData!.length > 0"
            style="width: 100%; height: 100%;"
            class="ag-theme-quartz ag-table"
            [domLayout]="'autoHeight'"
            [rowData]="rowData"
            [gridOptions]="gridOptions"
            [pagination]="agOptions.pagination"
            [paginationPageSize]="agOptions.paginationPageSize"
            [paginationPageSizeSelector]="agOptions.paginationPageSizeSelector"
            >
        </ag-grid-angular>
    </div>
</div>