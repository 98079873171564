<div class="d-flex w-100">
    <div class="d-flex flex-column h-100 me-3 w-100">
        <div class="card">
            <div class="d-flex text-center justify-content-center p-5 w-100" *ngIf="lobbies.length === 0">
                <span *ngIf="!fetchingData">Er zijn geen actieve wedstrijden.</span>
                <span *ngIf="fetchingData"><div class="loader"></div></span>
            </div>
            <table class="table table-striped w-100" *ngIf="lobbies.length > 0">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Naam</th>
                        <th>Map</th>
                        <th>Modus</th>
                        <th class="text-center">Spelers</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let lobby of lobbies; let i = index">
                        <td> {{ i + 1 }} </td>
                        <td> {{ lobby.name }} </td>
                        <td> {{ lobby.map.mapName}} </td>
                        <td> {{ lobby.mode.name }} </td>
                        <td class="text-center"> {{ lobby.players?.length }} </td>
                        <td>
                            <div class="d-flex">
                                <button class="w-50 btn btn-primary justify-content-center" routerLink="/admin/paintball/inspect/{{lobby.uuid}}"><i class="fa-solid fa-magnifying-glass"></i></button>
                                <!-- <button class="w-50 btn btn-danger justify-content-center" (click)="kickPlayer(player.userData.ids.userId)">
                                    <i class="fa-solid fa-person-to-door" *ngIf="!pendingKicks.includes(player.userData.ids.userId)"></i>
                                    <div class="loader" *ngIf="pendingKicks.includes(player.userData.ids.userId)"></div>
                                </button> -->
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>